<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Data Cabang" [options]="false">
            <div class="tabel-container">
                <button (click)="cabangBaru()" class="btn btn-success m-2">
                    + Kantor/Gudang Baru
                </button>
                <ngb-progressbar *ngIf="isLoading" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Kode</th>
                            <th>Nama</th>
                            <th>Telp.</th>
                            <th>Alamat</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data; let i = index">
                            <td>{{(i + 1)}}</td>
                            <td>{{item.code}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.phone_number}}</td>
                            <td>{{item.address}}</td>
                            <td>{{entity_types[item.type_id]}}</td> 
                            <td>
                                <a routerLink="/settings/cabang/edit/{{item.id}}">Edit</a>
                                 / 
                                <a *ngIf="item.entity_type_id != 1" href="javascript:;" class="text-danger" (click)="hapus(i)">Hapus</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>
    </div>
</div>