import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_ORIGIN } from '../app.variables';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class UserService {
  urls = URL_ORIGIN
  constructor(private cookies : CookieService, private http : HttpClient) { }

  public getProfile() : Observable<any>{
    return new Observable(observer=>{
      const token = this.cookies.get('token_admin_kezora');
      const httpOptions = {
        headers : new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + token
        })
      }
      this.http.get(this.urls + 'auth/profile', httpOptions).subscribe((res : any)=>{
        observer.next(res);
      }, error=>{
        observer.next({});
      })
    })
  }

  public hasLogin(){
    if(this.cookies.check('token_admin_kezora'))
      return true;
    else return false;
  }

  public getUser() : any{
    if(!this.hasLogin()) return {};
    return this.getDecode();
  }

  public logout(){
    this.cookies.delete('token_admin_kezora', '/')
  }

  getToken(){
    return this.cookies.get('token_admin_kezora')
  }

  getDecode(){
    try{
      return jwt_decode(this.cookies.get('token_admin_kezora'))
    }catch(Error){
      return {}
    }
  }
}
