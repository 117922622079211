<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Data Customer" [options]="false">
            <form (submit)="doCari()">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Customer</label>
                                    <div class="col-sm-9">
                                        <input name="search" [(ngModel)]="filter.search" type="search" class="form-control"
                                            placeholder="Cari nama customer" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button type="submit" class="btn btn-success m-1">Cari</button>
                                <button type="button" (click)="doDownload()" class="btn btn-info m-1">Download Excel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="tabel-container">
                <ngb-progressbar *ngIf="isLoading" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Nama</th>
                            <th>Kode</th>
                            <th>No. Telp</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data; let i = index">
                            <td>{{(i + 1) + (page * 20)}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.code}}</td>
                            <td>{{item.phone_number}}</td>
                            <td>
                                <a routerLink="/customer/data/edit/{{item.id}}">Edit</a>
                                /
                                <a class="text-danger" href="javascript:;" (click)="hapus(item.id)">Hapus</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination [collectionSize]="total_page"
                    [pageSize]="20" [(page)]="current_page" (pageChange)="changePage($event)" [maxSize]="5"
                    [rotate]="true" [boundaryLinks]="true"></ngb-pagination></div>
        </app-card>
    </div>
</div>