import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-input-tipe-product',
  templateUrl: './input-tipe-product.component.html',
  styleUrls: ['./input-tipe-product.component.scss']
})
export class InputTipeProductComponent implements OnInit { 

  data_bahan : any = []
  isLoading = false
  product = {
    id : 0,
    name : ''
  }

  required = ['name']

  constructor(private go : GoService, private activatedRoute : ActivatedRoute, public location : Location, private toast: ToastrService) { 
    this.activatedRoute.params.subscribe(param=>{
      if(param.id){
        this.product.id = param.id
        this.go.authHttp.get('product-types/' + param.id)
        .subscribe((res : any)=>{
          this.product.name = res.name
        })
      }
    })
  }

  doSimpan(){
    this.isLoading = true
    let process = null
    if(this.product.id == 0) process = this.go.authHttp.post('product-types', this.product)
    else process = this.go.authHttp.put('product-types/' + this.product.id, this.product)
    process.subscribe((res : any)=>{
      this.isLoading = false
      this.toast.success('Data product berhasil disimpan' , 'Product')
      if(this.product.id == 0) this.reset()
      else this.location.back()
    }, error=>{
      this.isLoading = false
      this.toast.error('Data product gagal disimpan' , 'Product')
    })
  }

  isDisabled(){
    for(let key of this.required){
     if(key != 'id'){
      if(!this.product[key]) return true
     }
    }
    return false
  }

  reset(){
    this.product.id = 0
    this.product.name = ''
  }

  ngOnInit(): void {
  }

}
