<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Outlet Sales Force" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Outlet SF">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sales Force</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.user_id" placeholder="Pilih Sales Force"
                                                [items]="data_sf" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Hari Kunjungan</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.day" placeholder="Pilih Hari" [items]="days"
                                                bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button [disabled]="!filter.user_id" (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Nama Outlet</th>
                                            <th>Kecamatan</th>
                                            <th>Owner</th>
                                            <th>Sales Force</th>
                                            <th>Hari Kunjungan</th>
                                            <th>Dibuat</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{item.outlet ? item.outlet.name : '-'}}</td>
                                            <td>{{item.outlet.regency ? item.outlet.regency.name : '-'}}</td>
                                            <td>{{item.outlet ? item.outlet.owner : '-'}}</td>
                                            <td>{{item.sales ? item.sales.name : '-'}}</td>
                                            <td>{{days[item.day-1].name}}</td>
                                            <td>{{item.user_updated_by ? item.user_updated_by.name : '-'}}</td>
                                            <td>
                                                <a class="text-danger" (click)="hapus(item, i)" href="javascript:;">Hapus</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Tambah Outlet SF">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sales Force</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.user_id" placeholder="Pilih Sales Force"
                                                [items]="data_sf" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Outlet</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.outlet_id" placeholder="Pilih Outlet"
                                                [items]="data_outlet" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Hari Kunjungan</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.day" placeholder="Pilih Hari" [items]="days"
                                                bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button [disabled]="isDisabled()" (click)="doSimpan()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Simpan
                                        </span>
                                    </button> &nbsp;
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>