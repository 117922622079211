import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-ganti-password',
  templateUrl: './ganti-password.component.html',
  styleUrls: ['./ganti-password.component.scss']
})
export class GantiPasswordComponent implements OnInit {
  ganti = {
    password : '',
    new_password : '',
    re_new_password : ''
  }

  isLoading = false
  constructor(public location : Location, private go : GoService, private toast : ToastrService) { }

  doSimpan(){
    this.isLoading = true
    this.go.authHttp.post('settings/ganti-password', this.ganti)
    .subscribe((res : any)=>{
      this.isLoading = false
      this.toast.success('Password anda berhasil diganti', 'Ganti Password')
      this.ganti.password = ''
      this.ganti.new_password = ''
      this.ganti.re_new_password = ''
    }, error=>{
      this.isLoading = false
      this.toast.warning(error.error.message, 'Ganti Password')
    })
  }

  ngOnInit(): void {
  }

}
