<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Data Tipe Product" [options]="false">
            <div class="tabel-container">
                <div class="row">
                    <div class="col-md-4">
                        
                    </div>
                    <div class="col-md-8 text-right">
                        <button (click)="tambah()" class="btn btn-success m-2">+ Tipe Product</button>
                    </div>
                </div>

                <ngb-progressbar *ngIf="isLoading" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Nama Tipe Produk</th>
                            <th class="text-center">Diupdate</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data_product; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.name}}</td>
                            <td class="text-center">{{item.updatedAt | date:"dd/MM/yyyy HH:mm"}}</td>
                            <td class="text-right">
                                <a routerLink="detail/{{item.id}}">Edit</a> / 
                                <a (click)="hapus(item)" class="text-danger" href="javascript:;">Hapus</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>
    </div>
</div>