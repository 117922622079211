<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Laporan Muatan Masuk " [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Muatan Masuk">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Gudang</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP($event, 'warehouse_id')" [(ngModel)]="filter.warehouse_id"
                                                placeholder="Pilih Gudang" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Coarri Codeco</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP($event, 'doc_code')" [(ngModel)]="filter.doc_code" placeholder="Pilih Dokumen Coarri Codeco"
                                                [items]="data_codes" bindLabel="description" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.to_date" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Voy/Flight</label>
                                        <div class="col-sm-9">
                                            <ng-select [disabled]="isLoadingstock" [(ngModel)]="filter.contract_id"
                                                placeholder="Pilih No. Voy/Flight" [items]="data_contracts"
                                                bindLabel="ship_number" bindValue="id">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="line">{{item.ship_number}}</div>
                                                    <div class="line"><small>({{item?.product?.name || '-'}})</small>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="doDownload()" class="btn btn-info">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Download Excel
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table" style="font-size: 12px;">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Gudang</th>
                                            <th>No. Voy/Flight</th>
                                            <th>Coarri Codeco</th>
                                            <th>Tanggal</th>
                                            <th>Angkutan</th>
                                            <th>Plat Truk</th>
                                            <th>Supir</th>
                                            <th>Palka</th>
                                            <th>Kemasan</th>
                                            <th>No. Kemasan</th>
                                            <th>Jenis Barang</th>
                                            <th>Satuan</th>
                                            <th class="text-right">Brutto</th>
                                            <th class="text-right">Tarra</th>
                                            <th class="text-right">Netto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{i+1 + ((filter.page - 1) * 10)}}</td>
                                            <td>{{item?.warehouse?.name}}</td>
                                            <td>{{item?.contract?.ship_number}}</td>
                                            <td>{{item?.contract?.doc_label}}</td>
                                            <td>{{item?.actual_date | date:"dd/MM/yyyy"}}</td>
                                            <td>{{item?.transport?.name}}</td>
                                            <td>{{item?.transport_truck?.plate_number}}</td>
                                            <td>{{item?.transport_truck?.driver_name}}</td>
                                            <td>Palka {{item.palka}}</td>
                                            <td>{{item.container == 1 ? 'Kontainer' : 'Non-Kontainer'}}</td>
                                            <td>{{item.package_number || '-'}}</td>
                                            <td>{{item?.contract?.product?.name}}</td>
                                            <td>{{item?.contract?.product?.unit}}</td>
                                            <td class="text-right">{{item.brutto | number}}</td>
                                            <td class="text-right">{{item.tarra | number}}</td>
                                            <td class="text-right">{{item.netto | number}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination
                                    [collectionSize]="total_page" [pageSize]="20" [(page)]="filter.page"
                                    (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true"
                                    [boundaryLinks]="true"></ngb-pagination></div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>