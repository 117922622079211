import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { days, roles } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-outlet-sf',
  templateUrl: './outlet-sf.component.html',
  styleUrls: ['./outlet-sf.component.scss']
})
export class OutletSfComponent implements OnInit {
  data_tap : any = []
  data_sf : any = []
  data_outlet : any = []
  days = days
  filter = {
    entity_id : null,
    user_id : null,
    day : null,
    outlet_id : null
  }
  roles = roles

  isLoading = false

  items : any = []
  constructor(private go : GoService, private toast: ToastrService) { 
    this.getDataTap() 
  }

  getDataTap(){
    this.go.authHttp.get('entities?entity_type_id=2')
    .subscribe((res : any)=>{
      this.data_tap = res
    })
  }

  selectTAP(ev){
    if(ev){
      this.filter.entity_id = ev.id
      this.getDataUsers()
      this.getDataOutlet()
    }
  } 

  getDataUsers(){
    this.go.authHttp.get('users/list?role=' + this.roles.SALES_FORCE + '&entity_id=' + this.filter.entity_id) 
    .subscribe((res : any)=>{
      this.data_sf = res
    })
  }

  getDataOutlet(){
    this.go.authHttp.get('entities?entity_type_id=3&parent_id=' + this.filter.entity_id) 
    .subscribe((res : any)=>{
      this.data_outlet = res
    })
  }

  doSimpan(){
    if(this.isLoading) return false
    this.isLoading = true
    let data = {
      entity_id : this.filter.outlet_id,
      user_id : this.filter.user_id,
      day: this.filter.day
    }

    this.go.authHttp.post('sales-force/create-outlet', data)
    .subscribe((res : any)=>{
      this.isLoading = false
      this.filter.outlet_id = null
      this.toast.success('Data outlet berhasil ditambahkan', 'Outlet SF')
    }, error=>{
      console.log(error)
      this.isLoading = false
      this.toast.error(error.error.message, 'Outlet SF')
    })
  }

  doCari(){
    this.isLoading = true
    let params = this.filter.day ? '&day=' + this.filter.day : ''
    this.go.authHttp.get('sales-force/outlets?user_id=' + this.filter.user_id + params)
    .subscribe((res : any)=>{
      this.isLoading = false
      this.items = res
    }, error=>{
      this.isLoading = false
      this.toast.error('Data gagal ditampilkan', 'Outlet SF')
    })
  }

  hapus(item, index){
    var id = item.id
    this.go.showConfirm('Data Outlet SF', 'Apakah anda yakin akan menghapus data ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('sales-force/outlet/' + id)
        .subscribe((res: any)=>{
          this.toast.success('Data outlet sf berhasil dihapus', 'Data Outlet SF')
          this.items.splice(index, 1)
        }, error=>{
          this.toast.error('Data outlet sf dihapus', 'Data Outlet SF')
        })
      }
    })
  }

  reset(){
    this.items = []
    this.filter.entity_id = null
    this.filter.user_id = null
    this.filter.day = null
    this.filter.outlet_id = null
  }

  isDisabled(){
    for(let key in this.filter){
      if(!this.filter[key]) return true
    }
    return false
  }

  ngOnInit(): void {
  }

}
