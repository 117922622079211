<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Preview Invoice</h4>
  </div>
  <div id="invoice" class="modal-body">
    <div class="invoice-container">
      <div class="top-content">
        <img class="logo-zirmon" src="./assets/images/logo-kezora-round.png" />
  
        <div class="column">
          <h4 style="margin-top: -5px;">Kezora</h4>
          <div>{{order.alamat_cabang}} {{order.kota}}</div>
          <div>Phone : {{order.telp_cabang}}</div>
          <div>Email : {{order.email_cabang}}</div>
        </div>
  
        <div class="column">
          <div style="margin-top: -5px;">Pembayaran dengan transfer dapat dilakukan ke rekening berikut :</div>
          <table class="table-bank">
            <tr>
              <td>BCA</td>
              <td>: 1950775030 a/n Kelvin Tantono</td>
            </tr>
            <tr>
              <td>Mandiri</td>
              <td>: 1060029295030 a/n Kelvin Tantono</td>
            </tr>
          </table>
        </div>
  
        <div style="clear: both;"></div>
      </div>
  
      <div class="main-content">
        <h4 class="title-invoice">INVOICE</h4>
  
        <div class="column-main1">
          <table class="table-main">
            <tr>
              <td style="width: 2cm;">ID Order </td>
              <td>:</td><td> {{data.kode_order}}</td>
            </tr>
            
            <tr>
              <td>Dokter</td>
              <td>:</td><td> {{order.customer}}</td>
            </tr>
            <tr>
              <td valign="top">Alamat</td>
              <td valign="top">:</td><td valign="top"> {{order.alamat_customer}}</td>
            </tr>
            <tr>
              <td>Telp</td>
              <td>:</td><td> {{order.no_hp_customer}}</td>
            </tr>
          </table>
        </div>
  
  
        <div class="column-main2">
          <table class="table-main">
            <tr>
              <td>Pasien </td>
              <td>: {{order.nama_pasien}}</td>
            </tr>
            <tr>
              <td>Tgl Order </td>
              <td>: {{data.tanggal | date:"dd/MM/yyyy"}}</td>
            </tr>
            <tr>
              <td>Tgl Selesai </td>
              <td>: {{data.tanggal_selesai | date:"dd/MM/yyyy"}}</td>
            </tr>
          </table>
        </div>
  
        <div style="clear: both;"></div>
  
        <h4 class="title-invoice">ORDER DETAIL</h4>
  
        <table class="order-detail" cellspacing="0" cellpadding="0">
          <tr>
            <th>Nama Produk</th>
            <th>Bahan</th>
            <th>Warna</th>
            <th align="right">Harga</th>
            <th align="center">Qty</th>
            <th align="right">Total Harga</th>
            <th>Posisi</th>
          </tr>
          <tr *ngFor="let item of detail_order">
            <td>{{item.nama_product}}</td>
            <td>{{item.bahan_product}}</td>
            <td>{{item.warna}}</td>
            <td style="text-align: right;">{{item.harga | number}}</td>
            <td style="text-align: center;">{{item.posisi.length | number}}</td>
            <td style="text-align: right;">{{item.posisi.length * item.harga | number}}</td>
            <td>
              <span
                *ngFor="let itm of item.posisi | sortBy : ['pos']; let i = index">{{i > 0 ? ', ' : ''}}{{itm.pos}}</span>
            </td>
          </tr>
        </table>
  
  
        <div class="column-ttd">
          <table class="ttd">
            <tr>
              <td>
                <span class="ttd2">
                  Diketahui Oleh,
                </span>
              </td>
  
              <td style="text-align: right;">
                <span class="ttd3">
                  Diterima Oleh,
                </span>
              </td>
            </tr>
          </table>
        </div>
  
        <div class="column-main2" style="float: right !important; padding-right: 0px;">
          <table class="order-detail" border="1" cellspacing="0" cellpadding="0">
            <tr><td>Sub Total</td><td align="center">:</td><td align="right"> {{getSubTotal() | number}} </td></tr>
            <tr *ngIf="order.diskon>0"><td>Diskon ({{order.diskon}}%)</td><td align="center">:</td><td align="right"> -{{order.diskon/100 * getSubTotal() | number}} </td></tr>
            <tr><td>Extra Charge</td><td align="center">:</td><td align="right"> {{order.extra_charge | number}} </td></tr>
            <tr><td>Ongkir</td><td align="center">:</td><td align="right"> {{order.ongkir | number}} </td></tr>
            <tr *ngIf="order.poin>0"><td>Poin</td><td align="center">:</td><td align="right"> -{{order.poin | number}} </td></tr>
            <tr><td>Grand Total</td><td align="center">:</td><td align="right"> {{getTotal() | number}} </td></tr>
          </table>
        </div>
  
        <div style="clear: both;"></div>
  
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-simple btn-danger" (click)="tutup()">Tutup</button>
    <button type="button" class="btn btn-simple btn-success" (click)="printSticker()">Print</button>
  </div>