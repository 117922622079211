<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Detail Order" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Kode Order</label>
                        <div class="col-sm-9">
                            <input readonly [(ngModel)]="order.kode_order" type="text" class="form-control"
                                placeholder="Kode Order">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Customer</label>
                        <div class="col-sm-9">
                            <ng-select [disabled]="user.id_cabang == 0" [(ngModel)]="order.id_customer" placeholder="Pilih Customer"
                                [items]="data_customer" bindLabel="nama" bindValue="id">
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="line">{{item.nama}}</div>
                                    <div class="line"><small>({{item.no_hp}}) , {{item.nama_kota}}</small></div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Pasien</label>
                        <div class="col-sm-9">
                            <input [disabled]="user.id_cabang == 0" [(ngModel)]="order.nama_pasien" type="text" class="form-control"
                                placeholder="Nama Pasien">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="order.keterangan" type="text" class="form-control"
                                placeholder="Keterangan Order">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal</label>
                        <div class="col-sm-4">
                            <input [disabled]="user.id_cabang == 0" [(ngModel)]="order.tanggal" type="date" class="form-control"
                                placeholder="Tanggal Masuk Order">
                        </div>
                    </div>
                </div>


            </div>

            <!-- Step journey Order -->
            <!-- Horizontal Steppers -->
            <div class="row">
                <div class="col-md-12">
                    <ngb-progressbar type="success" [value]="((status) / (status_order.length-1)) * 100" [animated]="false"></ngb-progressbar>
                    <table style="width: 100%; margin-top: 10px; margin-bottom: 10px;">
                        <tr>
                            <td [style.width]="(100/status_order.length) + '%'" *ngFor="let step of status_order; let i = index;" [ngClass]="{'text-right' : i == status_order.length-1}">
                                <div class="txt-date" [ngClass]="{'text-right' : i == status_order.length-1}">
                                    <span *ngIf="getDateStatus(step.id)"> {{getDateStatus(step.id) | date:"dd/MM/yyyy"}} </span>
                                    <input (click)="changeStatus(step)" type="checkbox" *ngIf="!getDateStatus(step.id) && order.status + 1 == step.id && enableChecked(step.id)"  />
                                </div>
                                {{step.text}}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <!-- /.Horizontal Steppers -->

            <!-- detail order -->
            <div class="row">
                <div class="col-md-8">
                    <h5 style="margin-top: 10px;">Detail Order</h5>
                    <table class="table no-border" style="font-size: 12px">
                        <tr>
                            <th>No.</th>
                            <th>Nama Product</th>
                            <th>Bahan</th>
                            <th>Warna</th>
                            <th>Posisi</th>
                            <th class="text-right">Harga</th>
                            <th class="text-right">
                                <span *ngIf="user.id_cabang == 0">Harga Mitra</span>
                            </th>
                        </tr>
                        <tr *ngFor="let item of detail_order; let i = index">
                            <td>{{i + 1}}</td>
                            <td>{{item.nama_product}}</td>
                            <td>{{item.bahan_product}}</td>
                            <td>{{item.warna}}</td>
                            <td>{{item.posisi}}</td>
                            <td class="text-right">{{item.harga_product | number}}</td>
                            <td class="text-right">
                                <a *ngIf="user.id_cabang != 0" (click)="hapus(i)" class="text-danger">Hapus</a>
                                <span *ngIf="user.id_cabang == 0">
                                    {{item.harga_mitra | number}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><b>Sub Total</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right"><strong>{{getSubTotal() | number}}</strong></td>
                            <td class="text-right">
                                <strong *ngIf="user.id_cabang == 0">{{getSubTotal2() | number}}</strong>
                            </td>
                        </tr>

                        <tr *ngIf="detail_order.length >0">
                            <td></td>
                            <td><b>Extra Charge</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right"><input [disabled]="user.id_cabang == 0" type="text" currencyMask
                                    [options]="{ prefix: '', precision :0, allowNegative : false, align : 'right', nullable: true}"
                                    [(ngModel)]="order.extra_charge" /></td>
                            <td></td>
                        </tr>
                        <tr *ngIf="detail_order.length >0">
                            <td></td>
                            <td><b>Ongkir</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right"><input [disabled]="user.id_cabang == 0" type="text" currencyMask
                                    [options]="{ prefix: '', precision :0, allowNegative : false, align : 'right', nullable: true}"
                                    [(ngModel)]="order.ongkir" /></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td><b>Total</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right"><strong>{{getTotal() | number}}</strong></td>
                            <td class="text-right"><strong *ngIf="user.id_cabang == 0">{{getSubTotal2() | number}}</strong></td>
                        </tr>

                    </table>
                </div>
                <!-- start -->
                <div class="col-md-4">
                    <div class="row pilih-product-container">
                        <p *ngIf="user.id_cabang != 0">*Pilih product, warna, & posisi gigi</p>
                        <div *ngIf="user.id_cabang != 0" class="col-7">

                            <div class="form-group">
                                <label>Product</label>
                                <ng-select name="customer" (change)="selectProduct($event)" [items]="data_products"
                                    [(ngModel)]="product.id_product" [virtualScroll]="true" bindLabel="nama"
                                    bindValue="id" placeholder="Product" required>
                                    <ng-template ng-label-tmp let-item="item">
                                        <span>{{item.nama}}</span><br />
                                        <i>{{item.bahan}}</i>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        <span>{{item.nama}}</span><br />
                                        <i>{{item.bahan}}</i>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div *ngIf="user.id_cabang != 0" class="col-5">
                            <div class="form-group">
                                <label>Warna</label>
                                <input oninput="this.value = this.value.toUpperCase()" [(ngModel)]="product.warna"
                                    type="text" class="form-control" name="warna" placeholder="Warna" />
                            </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <h4 class="text-center">Posisi Gigi</h4>
                            <svg class="susunan-gigi">
                                <circle *ngFor="let item of susunan_gigi" (click)="getPosisi(item.posisi)"
                                    [ngClass]="{'selected' : selectedPosisi(item.posisi)}" [attr.cx]="item.cx"
                                    [attr.cy]="item.cy" [attr.r]="item.r" class="circle">
                                    <title>{{getSelectedPosisi(item.posisi).length}} Items</title>
                                </circle>
                            </svg>
                        </div>
                    </div>
                </div>
                <!-- end -->
            </div>

            <div class="row">
                <div class="col-md-6">
                   
                    <div class="col-sm-12">
                        <button [disabled]="isDisabled()" (click)="doSimpan()" class="btn  btn-primary m-2"
                            type="button">
                            <span *ngIf="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status"></span>
                                Loading...
                            </span>
                            <span *ngIf="!isLoading">
                                Update
                            </span>
                        </button>

                        <button *ngIf="user.id_cabang != 0" (click)="doHapus()" class="btn btn-danger m-2" type="button">
                            <span *ngIf="isLoadingHapus">
                                <span class="spinner-border spinner-border-sm" role="status"></span>
                                Loading...
                            </span>
                            <span *ngIf="!isLoadingHapus">
                                Hapus
                            </span>
                        </button>

                        <button  (click)="location.back()" class="btn btn-light m-2" type="button">
                            Kembali
                        </button>

                        <button *ngIf="user.id_cabang != 0" (click)="cetakInvoice()" class="btn btn-info m-2">Print Invoice</button>

                        <button (click)="cetakSticker()" class="btn btn-info m-2">Print Label</button>
                    </div>
                </div>
            </div>


        </app-card>
    </div>
</div>