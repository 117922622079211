<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Data User" [options]="false">
            <div class="tabel-container">
                <form (submit)="doCari()">
                    <div class="row">
                        <div class="col-md-6">
                            <input name="search" [(ngModel)]="search" type="search" class="form-control" placeholder="Cari nama user" />
                        </div>
                        <div class="col-md-4">
                            <button type="submit" style="margin-top:-1px !important; margin-left:-25px !important" class="btn btn-success m-2">Cari</button>
                        </div>
                    </div>
                </form>
                <ngb-progressbar *ngIf="isLoading" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th></th>
                            <th>Username</th>
                            <th>Nama</th>
                            <th>Email</th>
                            <th>No. Hp</th>
                            <th>Entitas</th>
                            <th>Privilege</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data; let i = index">
                            <td>{{(i + 1) + (page * 20)}}</td>
                            <td>
                                <img class="image-user" [src]="urls + 'images/' + (item.image ? item.image : 'user-default.png')" />
                            </td>
                            <td>{{item.username}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.email}}</td>
                            <td>{{item.phone_number}}</td>
                            <td>{{item?.entity?.name || ''}}</td>
                            <td>{{privileges[item.role]}}</td>
                            <td>
                                <a routerLink="/user/data/edit/{{item.id}}">Edit</a>
                                 / 
                                <a class="text-danger" (click)="hapus(item)" href="javascript:;">Hapus</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination [collectionSize]="total_page" [pageSize]="20" [(page)]="current_page" (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination></div>
        </app-card>
    </div>
</div>