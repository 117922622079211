import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URL_ORIGIN } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-kunjungan-detail',
  templateUrl: './kunjungan-detail.component.html',
  styleUrls: ['./kunjungan-detail.component.scss']
})
export class KunjunganDetailComponent implements OnInit {
  visits: any = []
  id = null
  urls = URL_ORIGIN
  constructor(private go: GoService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        this.id = param.id
        this.getData()
      }
    })
  }

  mode_map = ''
  initLng = 98.68073699817948
  initLat = 3.579070780816168

  items: any = []
  getData() {
    this.go.authHttp.get('sales-force/visits?id=' + this.id)
      .subscribe((res: any) => {
        this.visits = res
        for (let item of this.visits) {
          this.initLng = item.longitude
          this.initLat = item.latitude
          this.items.push({
            longitude: item.longitude,
            latitude: item.latitude,
            icon : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            content: '<strong>' + item.sales.name + '</strong>'
          })

          this.items.push({
            longitude: item.outlet.longitude,
            latitude: item.outlet.latitude,
            icon : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
            content: '<strong>' + item.outlet.name + '</strong>'
          })
        }
      })
  }

  getTotal(transactions) {
    let total = 0
    for (let item of transactions) total += item.qty * item.price

    return total
  }

  ngOnInit(): void {
  }

}
