import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { days, OPTION_CURRENCY_MASK, roles } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-distribusi-tap',
  templateUrl: './distribusi-tap.component.html',
  styleUrls: ['./distribusi-tap.component.scss']
})
export class DistribusiTapComponent implements OnInit {
  option_currency = OPTION_CURRENCY_MASK
  data_tap: any = []
  data_product: any = []
  data_stocks: any = []
  data_customer: any = []
  days = days
  filter = {
    customer_id: null,
    warehouse_id: null,
    product_id : null,
    from_date : null,
    to_date : null
  }
  roles = roles

  isLoading = false

  items: any = []

  bagging_contract = {
    ship_number: null,
    customer_id: null,
    warehouse_id: null,
    product_id: null,
    party: null,
    party_nett: 0,
    description: '',
    contract_date: this.go.formatDate(new Date()),
    doc_code: null,
    transport_name: null,
    call_sign: null,
    ref_number: null
  }

  data_codes : any  = []
  constructor(private go: GoService, private toast: ToastrService) {
    this.getDataTap()
    this.getDataProduct()
    this.getDataCustomer()
    this.getCoarriCodeco()
  }

  getCoarriCodeco(){
    this.go.authHttp.get('code-coarri-codeco')
    .subscribe((res : any)=>{
      this.data_codes = res
    })
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  getDataCustomer(){
    this.go.authHttp.get('entities?entity_type_id=3')
      .subscribe((res: any) => {
        this.data_customer = res
      })
  }

  getDataProduct() {
    this.go.authHttp.get('products')
      .subscribe((res: any) => {
        this.data_product = res.data
      })
  }

  isLoadingstock = false


  doSimpan() {
    if (this.isLoading) return false
    this.isLoading = true

    this.go.authHttp.post('bagging-contract', this.bagging_contract)
      .subscribe((res: any) => {
        this.isLoading = false
        this.bagging_contract.product_id = null
        this.bagging_contract.customer_id = null
        this.bagging_contract.warehouse_id = null
        this.bagging_contract.ship_number = ''
        this.bagging_contract.party_nett = null
        this.bagging_contract.party = null
        this.bagging_contract.description = ''
        this.bagging_contract.call_sign = null
        this.bagging_contract.doc_code = null
        this.bagging_contract.ref_number = null
        this.bagging_contract.transport_name = null
        this.toast.success('Data kontrak baging berhasil ditambahkan', 'Kontrak Baging')
      }, error => {
        console.log(error)
        this.isLoading = false
        this.toast.error(error.error.message, 'Kontrak Baging')
      })
  }

  doCari() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for(let key in this.filter){
      if(this.filter[key]) params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('bagging-contract/list?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Kontrak Baging')
      })
  }

  reset() {
    this.items = []
    this.filter.warehouse_id = null
    this.filter.product_id = null
    this.filter.from_date = null
    this.filter.to_date = null
  }

  getUnit(){
    let selected = this.data_product.filter(it=> it.id === this.bagging_contract.product_id)
    if(selected.length>0) return ` (${selected[0].unit}) `
    else return ''
  }

  isDisabled() {
    return false
  }

  ngOnInit(): void {
  }

}
