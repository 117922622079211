import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { STATUS_INVOICE, STATUS_ORDER } from 'src/app/app.variables';
import { PrintInvoiceComponent } from 'src/app/components/print-invoice/print-invoice.component';
import { PrintLabelComponent } from 'src/app/components/print-label/print-label.component';
import { GoService } from 'src/app/services/go.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss']
})
export class ViewInvoiceComponent implements OnInit {
  data_customer: any = []
  order = {
    id : null,
    kode_order: '',
    id_customer: null,
    nama_pasien: '',
    diskon: 0,
    total: 0,
    extra_charge: 0,
    ongkir: 0,
    tanggal: null,
    tanggal_invoice : null,
    tanggal_bayar : null,
    keterangan: '',
    status_invoice : 0,
    status : 0
  }

  isLoading = false
  data_products: any = []

  susunan_gigi = [
    /*kiri atas */
    { cx: 36, cy: 190, r: 12, posisi: 18 },
    { cx: 38, cy: 159, r: 12, posisi: 17 },
    { cx: 42, cy: 120, r: 12, posisi: 16 },
    { cx: 47, cy: 90, r: 12, posisi: 15 },
    { cx: 55, cy: 67, r: 11, posisi: 14 },
    { cx: 63, cy: 45, r: 11, posisi: 13 },
    { cx: 84, cy: 32, r: 11, posisi: 12 },
    { cx: 110, cy: 27, r: 11, posisi: 11 },

    /* kanan atas */
    { cx: 141, cy: 27, r: 11, posisi: 21 },
    { cx: 167, cy: 32, r: 11, posisi: 22 },
    { cx: 188, cy: 45, r: 11, posisi: 23 },
    { cx: 196, cy: 67, r: 11, posisi: 24 },
    { cx: 204, cy: 90, r: 11, posisi: 25 },
    { cx: 209, cy: 120, r: 12, posisi: 26 },
    { cx: 213, cy: 159, r: 12, posisi: 27 },
    { cx: 215, cy: 190, r: 12, posisi: 28 },

    /* kiri bawah */
    { cx: 36, cy: 255, r: 12, posisi: 48 },
    { cx: 37, cy: 286, r: 12, posisi: 47 },
    { cx: 47, cy: 325, r: 11, posisi: 46 },
    { cx: 56, cy: 355, r: 11, posisi: 45 },
    { cx: 65, cy: 380, r: 11, posisi: 44 },
    { cx: 72, cy: 400, r: 9, posisi: 43 },
    { cx: 92, cy: 407, r: 9, posisi: 42 },
    { cx: 113, cy: 409, r: 9, posisi: 41 },

    /* kanan bawah */
    { cx: 137, cy: 409, r: 9, posisi: 31 },
    { cx: 158, cy: 407, r: 9, posisi: 32 },
    { cx: 178, cy: 400, r: 9, posisi: 33 },
    { cx: 185, cy: 380, r: 11, posisi: 34 },
    { cx: 194, cy: 355, r: 11, posisi: 35 },
    { cx: 203, cy: 325, r: 11, posisi: 36 },
    { cx: 211, cy: 286, r: 12, posisi: 37 },
    { cx: 210, cy: 255, r: 12, posisi: 38 },
  ]

  detail_order : any = []
  journey_order : any = []

  status_order = JSON.parse(JSON.stringify(STATUS_ORDER))

  status = 0

  user : any = {}

  status_invoice = STATUS_INVOICE

  constructor(private go: GoService, private activateRoute : ActivatedRoute, private toast : ToastrService, public location: Location, private modal : NgbModal, private userService : UserService) {
    this.getCustomers()


    this.go.authHttp.get('order/data-product')
      .subscribe((res: any) => {
        this.data_products = res;
      })

    this.activateRoute.params.subscribe(param=>{
      if(param.id){
        this.order.id = param.id
        this.getDataOrder()
      }
    })

    this.user = this.userService.getDecode()
  }

  getDataOrder(){
    this.go.authHttp.get('order/data-order/' + this.order.id)
    .subscribe((res : any)=>{
      var order = res.order
      this.order.kode_order = order.kode_order
      this.order.id_customer  = order.id_customer
      this.order.nama_pasien = order.nama_pasien
      this.order.diskon = order.diskon
      this.order.extra_charge = order.extra_charge
      this.order.ongkir = order.ongkir
      this.order.tanggal = order.tanggal
      this.order.keterangan = order.keterangan
      this.order.status = order.status
      this.status = order.status
      this.order.status_invoice = order.status_invoice
      this.order.tanggal_invoice = order.tanggal_invoice
      this.order.tanggal_bayar = order.tanggal_bayar ? this.go.formatDate(order.tanggal_bayar): null

      this.detail_order = res.details
      this.journey_order = res.journey
    })
  }

  cetakInvoice(){
    let modal = this.modal.open(PrintInvoiceComponent, {
      size : 'lg'
    })
    modal.componentInstance.data = this.order;
  }


  getDateStatus(value){
    var selected = this.journey_order.filter(it=> it.status == value)
    return selected.length > 0 ? new Date(selected[0].date) : null
  }

  getSubTotal() {
    let total = 0;
    for (let itm of this.detail_order) total += itm.harga_product
    return total
  }

  delete_details : any = []


  getTotal() {
    var total = this.getSubTotal() + this.order.ongkir + this.order.extra_charge
    return total <= 0 ? 0 : total;
  }

  selectedPosisi(posisi) {
    var selected = this.detail_order.filter(it => it.posisi == posisi)
    return selected.length > 0;
  }

  getSelectedPosisi(posisi) {
    var selected = this.detail_order.filter(it => it.posisi == posisi)
    return selected
  }


  isDisabled() {
    if(!this.order.id_customer) return true
    else if(!this.order.nama_pasien) return true
    else if(this.detail_order.length<=0) return true
    else if(!this.order.tanggal) return true
    return false
  }

  getCustomers() {
    this.data_customer = []
    this.go.authHttp.get('customer/data')
      .subscribe((res: any) => {
        this.data_customer = res
      })
  }

  isLoadingHapus = false

  doSimpan() {
    this.go.showConfirm('Update Invoice', 'Apakah anda yakin akan mengupdate invoice ini ?')
      .result.then(res => {
        if (res) {
          this.isLoading = true

          var data = {
            order: {
              id: this.order.id,
              kode_order : this.order.kode_order,
              diskon:this.order.diskon,
              extra_charge: this.order.extra_charge,
              ongkir: this.order.ongkir,
              total: this.getTotal(),
              kode_voucher : '',
              keterangan : this.order.keterangan,
              tanggal_invoice : this.order.tanggal_invoice,
              status_invoice : this.order.status_invoice
            }
          }
          this.go.authHttp.post('order/simpan-invoice', data)
            .subscribe((res: any) => {
              this.isLoading = false
              this.toast.success('Data invoice berhasil disimpan', 'Invoice')
              this.getDataOrder()
            }, error => {
              this.isLoading = false
              this.toast.error('Data invoice gagal disimpan', 'Invoice')
            })
        }
      })
  }

  changeStatus(item){
    item.checked = !item.checked
    if(item.checked){
      this.status = item.id
    }else{
      this.status = this.order.status
    }
  }


  ngOnInit(): void {
  }

}
