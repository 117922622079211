import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor() { }

  printSticker(id) {
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
          <style media="print">
         
          @media print {
            @page { size: auto;  margin: 3mm; }
            * {
                -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
                color-adjust: exact !important;                 /*Firefox*/
            }
            body{
              margin:0mm;
              font-family : Monospace;
            }
            .sticker-container{
              width: 9cm;
              min-height: 5cm;
              /*border: 1px dotted #ccc;*/
              font-size: 10px !important;
              /*margin: auto;*/
              padding: 5px;
              margin-top:20px;
              margin-left:20px;
              font-family : Monospace;
          }
          
          table{
              width: 100%;
          }

          .detail-order{
            margin-top:5px;
          }

          td{
            font-size:10px !important;
          }
          
          .left-content{
              float: left;
              width: 6cm;
          }
          
          .right-content{
              float: right;
              width: calc(2cm - 10px);
              position: relative;
              vertical-align: middle;
          }
          
          svg{
              width: 98%;
              margin-top: 0cm;
              margin-bottom:0cm;
              position:absolute;
              right:5px;
              top:0;
          }

          .td-id-order{
            width:40%;
          }

          .logo-kezora{
            width: 70px;
          }

          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }


  printInvoice(id) {
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title></title>
          <style media="print">
         
          @media print {
            @page { size: auto;  margin: 0mm; }
             * {
                -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
                color-adjust: exact !important;                 /*Firefox*/
            } 
            body{
              margin:0mm;
              font-family: Calibri;
              color : black;
              font-weight:light;
            }
            .invoice-container{
              padding: 20px;
              width: 90%;
              font-size: 10pt;
              font-family: Calibri;
              margin-top:5mm;
          }
          
          .top-content{
              padding: 5px;
              border-bottom: 1px solid #444;
          }

          table{
            font-size:10pt;
            color : black;
          }

          .main-content{
            padding: 8px;
          }
          
          .logo-zirmon{
              width: 1.5cm;
              float: left;
              margin-right: 20px;
          }
          
          .column{
              float: left;
              width: calc(50% - 50px);
          }
          
          h4{
              margin-bottom: 0px;
              margin-top:0px;
              font-size:15px;
          }
          
          .table-bank{
              width: 100%;
              font-size:10pt;
              color:black;
          }

          .table-bank td{
            font-size:10pt;
            color:black;
          }
          
          h4.title-invoice{
              text-align: center;
              text-decoration: underline;
              margin-bottom:2px;
          }
          
          .column-main1{
              float: left;
              width: 50%;
              padding: 10px;
          }
          
          .column-main2{
              float: left;
              width: calc(40% - 20px);
              padding: 10px;
          }
          
          .table-main{
              width: 100%;
              margin-top:-6px;
          }

          .table-main td{
            padding : 0px;
          }
          
          .order-detail{
              width: 100%;
          }
          
          table.order-detail{
            border-collapse: collapse;
          }
        
          .order-detail td, .order-detail th{
            padding: 1px;
            border:1px solid black;
          }

          .column-ttd{
            float: left;
            width: 55%;
            padding: 10px;
          }
          
          table.ttd{
              width: 100%;
              margin-top: 10px;
          }
          
          .ttd td{
              width: 50%;
          }
          
          .ttd2{
            padding-left: 10px;
            padding-right: 10px;
            border-bottom: 1px dotted #444; padding-bottom: 50px;
          }

          .ttd3{
            padding-left: 10px;
            padding-right: 10px;
            border-bottom: 1px dotted #444; padding-bottom: 50px;
          }

          

          }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
