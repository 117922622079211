import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { days, roles, transaction_type_id } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document : any
@Component({
  selector: 'app-report-coarri',
  templateUrl: './report-coarri.component.html',
  styleUrls: ['./report-coarri.component.scss']
})
export class ReportCoarriComponent implements OnInit {
  data_tap: any = []
  data_contracts : any = []
  days = days
  filter = {
    page : 1,
    warehouse_id: null,
    doc_code: null,
    contract_id: null,
    from_date: this.go.formatDate(new Date()),
    to_date: this.go.formatDate(new Date())
  }
  roles = roles

  isLoading = false

  items: any = []

  data_codes : any = []
  total_page = 0

  constructor(private go: GoService, private toast: ToastrService, private router: Router) {
    this.getDataTap()
    this.getCoarriCodeco()
  }

  getCoarriCodeco(){
    this.go.authHttp.get('code-coarri-codeco')
    .subscribe((res: any)=>{
      this.data_codes = res
    })
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }


  changePage(ev) {
    let params = ''
    this.filter.page = ev
    this.doCari()
  }

  doCari() {
    this.isLoading = true
    let params = ''
    
    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null){
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('bagging-contract/list-v2?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res.list
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Laporan Coarri Codeco')
      })
  }

  doDownload() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key] != null){
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.go.downloadFile('bagging-contract/download?' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  reset() {
    this.items = []
    this.filter.warehouse_id = null
    this.filter.doc_code = null
    this.filter.contract_id = null
  }

  isDisabled() {
    return false
  }

  getTotal() {
    let total = 0
    for (let item of this.items) total += item.qty * item.price

    return total
  }

  ngOnInit(): void {
  }

}
