import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OPTION_CURRENCY_MASK, PALKA } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-penerimaan-barang',
  templateUrl: './penerimaan-barang.component.html',
  styleUrls: ['./penerimaan-barang.component.scss']
})
export class PenerimaanBarangComponent implements OnInit {
  option_currency = OPTION_CURRENCY_MASK
  isLoading = false

  transaction = {
    warehouse_id: null,
    contract_id: null,
    actual_date: this.go.formatDate(new Date()),
    party: null,
    party_nett: null,
    description: '',
    container: 1,
    package_number: ''
  }

  transaction2 = {
    entity_id: null
  }

  data_gudang: any = []
  data_contracts : any = []
  baggage_receipts: any = []
  product : any = {}
  data_angkutan: any = []
  palka = PALKA

  data_baggages : any = []

  showAll = false

  containers = [
    {id: 1, title: 'Kontainer'},
    {id: 2, title: 'Non-Kontainer'}
  ]

  constructor(private go: GoService, private toast: ToastrService) {
    this.getGudang()
    this.getAngkutan()
  }

  getGudang() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_gudang = res
      })
  }

  getAngkutan(){
    this.go.authHttp.get('transports/list')
    .subscribe((res: any)=>{
      this.data_angkutan = res
    })
  }


  isLoadingstock = false
  selectGudang(ev) {
    if (ev) {
      this.getContract(ev.id)
    }
  }

  selectKapal(ev){
    this.product = {}
    this.transaction.party = null
    this.transaction.party_nett = null
    if(ev){
      const selected = this.data_contracts.filter(it=> it.id === ev.id)
      if(selected.length>0){
        this.product = selected[0]?.product
        this.transaction.party = selected[0].party
        this.transaction.party_nett = selected[0].party_nett

        this.getBaggageMasuk()
      }
    }
  }

  getContract(warehouse_id){
    this.isLoadingstock = true
    this.go.authHttp.get('bagging-contract/list?warehouse_id=' + warehouse_id)
    .subscribe((res: any)=>{
      this.data_contracts = res
      this.isLoadingstock = false
    })
  }


  jumlahTrip = 0
  totalBaggage = 0
  getBaggageMasuk() {
    this.data_baggages = []
    this.jumlahTrip = 0
    this.totalBaggage = 0
    const {contract_id, actual_date} = this.transaction
    if(contract_id && actual_date){
      this.go.authHttp.get('baggage-receipts/list-no-page?contract_id=' + contract_id + '&actual_date=' + actual_date)
      .subscribe((res: any)=>{
        this.data_baggages = res
        for(let item of this.data_baggages){
          this.totalBaggage += item.netto
        }
        this.jumlahTrip = this.data_baggages.length
      }, error=>{
        this.data_baggages = []
      })
    }
  }


  doSimpan() {
    if (this.isLoading) return false
    this.isLoading = true
    let total = 0
    for(let item of this.baggage_receipts){
      item.actual_date = this.transaction.actual_date
      item.warehouse_id = this.transaction.warehouse_id
      item.contract_id = this.transaction.contract_id
      item.description = this.transaction.description
      item.container = this.transaction.container
      item.package_number = this.transaction.package_number
      item.netto = item.brutto - item.tarra
      total += item.netto
    }

    this.go.authHttp.post('baggage-receipts', this.baggage_receipts)
      .subscribe((res: any) => {
        this.isLoading = false
        this.toast.success('Data barang masuk berhasil disimpan', 'Bagging Masuk')
        this.transaction.party_nett += total

        this.getBaggageMasuk()
        this.baggage_receipts = []
      }, error => {
        this.isLoading = false
        this.toast.error(error.error.message, 'Bagging Masuk')
      })
  }

  getUnit(){
    if(this.product?.unit) return ` (${this.product.unit}) `
    else return ''
  }

  reset() {
    this.transaction.contract_id = null
    this.transaction.warehouse_id = null
    this.transaction.description = ''
    this.transaction.party = ''
    this.transaction.party_nett = ''
    this.transaction.container = 1
    this.transaction.package_number = ''
    this.product = {}
    this.baggage_receipts = []
  }

  tambahAngkutan(){
    this.baggage_receipts.push({
      transport_id : null,
      transport_truck_id: null,
      palka : 1,
      brutto: null,
      tarra: null,
      netto: null
    })
  }

  getTruk(item){
    const selected = this.data_angkutan.filter(it=> it.id === item.transport_id)
    if(selected.length>0) return selected[0].trucks
    else return []
  }

  hapusTruk(i){
    this.baggage_receipts.splice(i, 1)
  }

  isDisabled() {
    if(!this.transaction.warehouse_id) return true
    if(!this.transaction.actual_date) return true
    if(this.baggage_receipts.length<=0) return true
    if(!this.baggage_receipts.container) return true
    if(!this.baggage_receipts.package_number) return true
    if(this.baggage_receipts.filter(it=> !it.transport_id || !it.transport_truck_id || !it.brutto).length>0) return true

    return false
  }

  ngOnInit(): void {
  }

}
