import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoService } from 'src/app/services/go.service';
import { ToastService } from 'src/app/theme/shared/components/toast/toast.service';

@Component({
  selector: 'app-input-customer',
  templateUrl: './input-customer.component.html',
  styleUrls: ['./input-customer.component.scss']
})
export class InputCustomerComponent implements OnInit {

  data_kota : any = []
  isLoading = false
  customer = {
    id : 0,
    name : '', address : '', phone_number : '', type_id : 3,
    code: ''
  }

  data_tap : any = []
  
  constructor(private go : GoService, private activatedRoute : ActivatedRoute, public location : Location, private toastEvent: ToastService) { 
    this.activatedRoute.params.subscribe(param=>{
      if(param.id){
        this.customer.id = param.id
        this.go.authHttp.get('entities/id/' + param.id)
        .subscribe((res : any)=>{
          this.customer.name = res.name
          this.customer.address = res.address
          this.customer.phone_number = res.phone_number
          this.customer.code = res.code
        })
      }
    })
  }

  simpan(){
    this.isLoading = true
    this.go.authHttp.post('entities', this.customer)
    .subscribe((res : any)=>{
      this.isLoading = false
      this.toastEvent.toast({uid: 'toastSuccess', delay: 3000, title : 'Data customer', content : 'Data customer berhasil disimpan'})
      this.reset()
    }, error=>{
      this.isLoading = false
      this.toastEvent.toast({uid: 'toastError', delay: 3000, title : 'Data customer', content : 'Data customer gagal disimpan'})
    })
  }

  doSimpan(){
    if(this.customer.id == 0) this.simpan()
    else this.edit()
  }

  edit(){
    this.isLoading = true
    this.go.authHttp.post('entities', this.customer)
    .subscribe((res : any)=>{
      this.isLoading = false
      this.toastEvent.toast({uid: 'toastSuccess', delay: 3000, title : 'Data customer', content : 'Data customer berhasil disimpan'})
      this.location.back()
    }, error=>{
      this.isLoading = false
      this.toastEvent.toast({uid: 'toastError', delay: 3000, title : 'Data customer', content : 'Data customer gagal disimpan'})
    })
  }

  isDisabled(){
    for(let key in this.customer){
     if(key != 'id'){
      if(!this.customer[key]) return true
     }
    }
    return false
  }

  reset(){
    this.customer.id = 0
    this.customer.name = ''
    this.customer.phone_number = ''
    this.customer.address = ''
    this.customer.code = ''
  }

  ngOnInit(): void {
  }

}
