import { Component, OnInit } from '@angular/core';
import { GoService } from 'src/app/services/go.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from '@amcharts/amcharts4/themes/material';


am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_material);

@Component({
  selector: 'app-statistik-cabang',
  templateUrl: './statistik-cabang.component.html',
  styleUrls: ['./statistik-cabang.component.scss']
})
export class StatistikCabangComponent implements OnInit {
  data_cabang: any = []
  stats: any = {
    id_cabang: null,
    email: '',
    alamat: ''
  }

  data : any = []
  constructor(private go: GoService) {
    this.getCabang()
  }

  ngOnInit(): void {
  }

  getCabang() {
    this.data_cabang = []
    this.go.authHttp.get('extra/cabang')
      .subscribe((res: any) => {
        this.data_cabang = res
      })
  }

  selectCabang(ev) {
    if (ev) {
      this.stats.email = ev.email;
      this.stats.alamat = ev.alamat

      this.go.authHttp.get('report/stats-order-cabang-12m/' + ev.id)
      .subscribe((res : any)=>{
        this.createChartOrder(res)
      })

      this.go.authHttp.get('report/data-order-cabang-30d/' + ev.id)
      .subscribe((res : any)=>{
        this.data = res
      })

    } else {
      this.stats.email = ''
      this.stats.alamat = ''
    }
  }

  createChartOrder(data) {
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    let previousValue;

    for (var i = 0; i < data.length; i++) {
      var value = data[i].total

      if (i > 0) {
        // add color to previous data item depending on whether current value is less or more than previous value
        if (previousValue <= value) {
          data[i - 1].color = chart.colors.getIndex(5);
        }
        else {
          data[i - 1].color = chart.colors.getIndex(0);
        }
      }
      previousValue = value;
    }


    chart.data = data;

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.dataFields.category = "periode";
    categoryAxis.startLocation = 0;
    categoryAxis.endLocation = 0.6;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "periode";
    series.dataFields.valueY = "total";
    series.strokeWidth = 2;
    series.tooltipText = "{categoryX}: {valueY} Item";

    // set stroke property field
    series.propertyFields.stroke = "color";

    chart.cursor = new am4charts.XYCursor();
  }

  getTotalJumlah(){
    let total = 0
    for(let item of this.data) total += item.jumlah
    return total
  }

  getTotalRp(){
    let total = 0
    for(let item of this.data) total += item.total
    return total
  }

}
