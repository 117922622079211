<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Ganti Password" [options]="false">
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Password Lama</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="ganti.password" type="password" class="form-control"
                                placeholder="Masukkan password lama anda">
                        </div>
                    </div>
                </div>

            </div>
 
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Password Baru</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="ganti.new_password" type="password" class="form-control"
                                placeholder="Masukkan password baru anda">
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Ulangi Password Baru</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="ganti.re_new_password" type="password" class="form-control"
                                placeholder="Ulangi password baru anda">
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="col-sm-3"> </div>
                    <div class="col-sm-9">
                        <button [disabled]="!ganti.password || !ganti.new_password || (ganti.new_password != ganti.re_new_password)" (click)="doSimpan()" class="btn  btn-primary m-2"
                            type="button">
                            <span *ngIf="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status"></span>
                                Loading...
                            </span>
                            <span *ngIf="!isLoading">
                                Ganti Password
                            </span>
                        </button>

                        <button (click)="location.back()" class="btn btn-danger m-2" type="button">
                            Kembali
                        </button>
                    </div>
                </div>
            </div>


        </app-card>
    </div>
</div>