
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-data-product',
  templateUrl: './data-product.component.html',
  styleUrls: ['./data-product.component.scss']
})
export class DataProductComponent implements OnInit {
  data_product : any = []
  isLoading = false
  nama = ''
  constructor(private go : GoService, private router : Router, private toast : ToastrService) {
    this.getData()
  }

  tambah(){
    this.router.navigateByUrl('/settings/products/tambah')
  }

  getData(){
    this.isLoading = true
    this.go.authHttp.get('products')
    .subscribe((res : any)=>{
      this.data_product = res.data
      this.isLoading = false
    })
  }

  hapus(item){
    this.go.showConfirm('Hapus Barang', 'Apakah anda yakin akan menghapus data ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('products/' + item.id) 
        .subscribe((res : any)=>{
          this.toast.success('Data barang berhasil dihapus' , 'Barang')
          this.getData()
        }, error=>{
          this.toast.error('Data barang gagal dihapus' , 'Barang')
        })
      }
    })
  }

  ngOnInit(): void {
  }

}
