import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { SearchPipe } from './search.pipe';
import { SortPipe } from './sort.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SafePipe,
    SearchPipe,
    SortPipe
  ],
  exports : [
    SafePipe,
    SearchPipe,
    SortPipe
  ]
})
export class PipesModule { }
