import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OPTION_CURRENCY_MASK, PALKA } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-bagging-order',
  templateUrl: './bagging-order.component.html',
  styleUrls: ['./bagging-order.component.scss']
})
export class BaggingOrderComponent implements OnInit {
  option_currency = OPTION_CURRENCY_MASK
  isLoading = false
  isLoadingSearch = true

  transaction = {
    warehouse_id: null,
    contract_id: null,
    actual_date: this.go.formatDate(new Date()),
    party: null,
    party_nett: null,
    qty: null,
    code: '',
    description: ''
  }

  filter = {
    warehouse_id: null,
    contract_id: null,
    from_date: null,
    to_date: null,
    page: 1
  }

  data_gudang: any = []
  data_contracts: any = []
  data_filter_contracts: any = []
  product: any = {}

  data_orders : any =[]

  showAll = false

  total_page = 0

  constructor(private go: GoService, private toast: ToastrService) {
    this.getGudang()
    this.getNoDO()
    this.doCari()
  }

  doCari(){
    this.isLoadingSearch = true
    const params = []
    for(let key in this.filter)
      if(this.filter[key])
        params.push(`${key}=${this.filter[key]}`)
    
    this.go.authHttp.get('orders/list?' + params.join('&'))
    .subscribe((res: any)=>{
      this.isLoadingSearch = false
      this.data_orders = res.list
      this.total_page = res.total
    })
  }

  getNoDO() {
    this.go.authHttp.get('orders/generate-do')
      .subscribe((res: any) => {
        this.transaction.code = res.code
      }, error => {
        this.transaction.code = ''
      })
  }

  getGudang() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_gudang = res
      })
  }

  isLoadingstock = false
  selectGudang(ev) {
    if (ev) {
      this.getContract(ev.id)
    }
  }

  selectGudangFilter(ev) {
    if (ev) {
      this.isLoadingstock = true
      this.go.authHttp.get('bagging-contract/list?warehouse_id=' + ev.id)
        .subscribe((res: any) => {
          this.data_filter_contracts = res
          this.isLoadingstock = false
        })
    }
  }

  selectKapal(ev) {
    this.product = {}
    this.transaction.party = ''
    this.transaction.party_nett = ''
    if (ev) {
      const selected = this.data_contracts.filter(it => it.id === ev.id)
      if (selected.length > 0) {
        this.product = selected[0]?.product
        this.transaction.party = selected[0].party
        this.transaction.party_nett = selected[0].party_nett
      }
    }
  }

  getContract(warehouse_id) {
    this.isLoadingstock = true
    this.go.authHttp.get('bagging-contract/list?warehouse_id=' + warehouse_id)
      .subscribe((res: any) => {
        this.data_contracts = res
        this.isLoadingstock = false
      })
  }

  changePage(ev){
    this.filter.page = ev
    this.doCari()
  }

  doSimpan() {
    if (this.isLoading) return false
    this.isLoading = true

    this.go.authHttp.post('orders', this.transaction)
      .subscribe((res: any) => {
        this.isLoading = false
        this.toast.success('Data order berhasil disimpan', 'Order')
        this.reset()
      }, error => {
        this.isLoading = false
        this.toast.error(error.error.message, 'Order')
      })
  }

  getUnit() {
    if (this.product?.unit) return ` (${this.product.unit}) `
    else return ''
  }

  reset() {
    this.transaction.contract_id = null
    this.transaction.warehouse_id = null
    this.transaction.description = ''
    this.transaction.party = null
    this.transaction.party_nett = null
    this.product = {}
    this.transaction.qty =
      this.getNoDO()
  }

  isDisabled() {
    if (!this.transaction.warehouse_id) return true
    if (!this.transaction.actual_date) return true
    if (!this.transaction.qty) return true
    if (!this.transaction.contract_id) return true
    return false
  }

  hapus(item){
    this.go.showConfirm('Hapus Order', 'Apakah anda yakin akan menghapus Nomor DO ' + item.code + ' ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('orders/' + item.id) 
        .subscribe((res : any)=>{
          this.toast.success('Data order muatan berhasil dihapus' , 'Order')
          this.doCari()
        }, error=>{
          this.toast.error('Data order muatan gagal dihapus' , 'Order')
        })
      }
    })
  }

  ngOnInit(): void {
  }

}
