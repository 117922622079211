import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-upload-pjp',
  templateUrl: './upload-pjp.component.html',
  styleUrls: ['./upload-pjp.component.scss']
})
export class UploadPjpComponent implements OnInit {
  upload = {
    file: null
  }

  isLoading = false
  constructor(private go : GoService, private toast: ToastrService) { }

  ngOnInit(): void {
  }

  downloadFormat() {
    this.go.downloadFile('xls/download-template/format-pjp')
      .subscribe((res: any) => {
        let fileName = 'template-pjp.xlsx'
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  fileExcel: any = null
  getFiles(ev) {
    if (ev.target.files) {
      this.fileExcel = ev.target.files[0]
    }
  }

  doUpload() {
    this.isLoading = true

    var data = new FormData()
    data.append('fileExcel', this.fileExcel)

    this.go.uploadFile('entities/upload-pjp', data)
      .subscribe((res: any) => {
        if (res.body) {
          this.isLoading = false
          this.upload.file = null
          this.toast.success(res.body.message, 'PJP Outlet')
        }
      }, error => {
        this.upload.file = null
        this.toast.error('Data pjp gagal diupload', 'PJP Outlet')
        this.isLoading = false
      })
  }

}
