<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Upload PJP Outlet" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Upload PJP">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">File Excel</label>
                                        <div class="col-sm-9">
                                            <input type="file" (change)="getFiles($event)" [(ngModel)]="upload.file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  class="form-control"
                                                placeholder="Pilih file">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doUpload()" [disabled]="!upload.file" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Upload
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="downloadFormat()" class="btn btn-info">
                                        <span>
                                            Download Format File
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>