import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-input-transport',
  templateUrl: './input-transport.component.html',
  styleUrls: ['./input-transport.component.scss']
})
export class InputTransportComponent implements OnInit {

  data_bahan: any = []
  isLoading = false
  transport = {
    name: '',
    phone_number: '',
    trucks: []
  }

  required = ['name']

  constructor(private go: GoService, private activatedRoute: ActivatedRoute, public location: Location, private toast: ToastrService) {

  }

  doSimpan() {
    if(this.isLoading) return false
    this.isLoading = true
    let process = this.go.authHttp.post('transports/create', this.transport)
    process.subscribe((res: any) => {
      this.isLoading = false
      this.toast.success('Data angkutan berhasil disimpan', 'Angkutan')
      this.reset()
    }, error => {
      this.isLoading = false
      this.toast.error('Data angkutan gagal disimpan', 'Angkutan')
    })
  }

  isDisabled() {
    if(!this.transport.name) return true
    if(this.transport.trucks.length<=0) return true
    if(this.transport.trucks.filter(it=> !it.plate_number || !it.driver_name || !it.code).length>0) return true
    return false
  }

  reset() {
    this.transport.name = ''
    this.transport.phone_number = ''
    this.transport.trucks = []
  }

  tambahTruk(){
    this.transport.trucks.push({
      plate_number : '',
      driver_name: '',
      code: '',
      phone_number: ''
    })
  }

  hapusTruk(index){
    this.transport.trucks.splice(index, 1)
  }

  ngOnInit(): void {
  }

}
