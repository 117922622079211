import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
  pusat?:boolean;
  cabang?:boolean;
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      /*{
        id: 'dashboard',
        title: 'Home',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home',
        breadcrumbs: false
      },*/
      {
        id : 'transaksi',
        title : 'Transaksi',
        type : 'collapse',
        icon : 'feather icon-shopping-cart',
        children: [
          {
            id : 'distribusi-tap',
            title : 'Coarri Codeco',
            type : 'item',
            url : '/transaksi/kontrak-bagging',
            cabang : true
          },
          {
            id : 'order-bagging',
            title : 'Order Muatan',
            type : 'item',
            url : '/transaksi/order-bagging'
          },
          {
            id : 'penerimaan-barang',
            title : 'Muatan Masuk',
            type : 'item',
            url : '/transaksi/penerimaan-barang'
          },
          {
            id : 'muatan-keluar',
            title : 'Muatan Keluar',
            type : 'item',
            url : '/transaksi/muatan-keluar'
          },
          {
            id : 'lihat-stock',
            title : 'Lihat Stock',
            type : 'item',
            url : '/transaksi/lihat-stock'
          }
        ]
      },

      {
        id : 'customer',
        title : 'Customer',
        type : 'collapse',
        icon : 'feather icon-pocket',
        children: [
          {
            id : 'baru',
            title : 'Customer Baru',
            type : 'item',
            url : '/customer/baru'
          },
          {
            id : 'data',
            title : 'Data Customer',
            type : 'item',
            url : '/customer/data'
          }
        ]
      },

      /*{
        id : 'sales',
        title : 'Sales Force',
        type : 'collapse',
        icon : 'feather icon-users',
        children: [
          {
            id : 'baru',
            title : 'Outlet Sales Force',
            type : 'item',
            url : '/sales-force/outlet'
          },
          {
            id : 'data',
            title : 'Kunjungan Outlet',
            type : 'item',
            url : '/sales-force/kunjungan'
          },
          {
            id : 'peta-kunjugan',
            title : 'Peta Kunjugan',
            type : 'item',
            url : '/sales-force/peta-kunjungan'
          },
          {
            id : 'tracking',
            title : 'Tracking',
            type : 'item',
            url : '/sales-force/tracking'
          }
        ]
      },*/

      {
        id : 'users',
        title : 'Users',
        type : 'collapse',
        icon : 'feather icon-user-check',
        children: [
          {
            id : 'baru',
            title : 'Tambah User',
            type : 'item',
            url : '/user/baru'
          },
          {
            id : 'data',
            title : 'Data User',
            type : 'item',
            url : '/user/data'
          }
        ]
      },

      {
        id : 'settings',
        title : 'Settings',
        type : 'collapse',
        icon : 'feather icon-settings',
        children: [
          {
            id : 'settings-password',
            title : 'Ganti Password',
            type : 'item',
            url : '/settings/ganti-password'
          },
          {
            id : 'cabang',
            title : 'Kantor/Gudang',
            type : 'item',
            url : '/settings/cabang',
            pusat : true
          },
          {
            id : 'data-product',
            title : 'Barang',
            type : 'item',
            url : '/settings/products',
            pusat : true
          },
          {
            id : 'data-tipe-product',
            title : 'Tipe Barang',
            type : 'item',
            url : '/settings/tipe-product',
            pusat : true
          },
          {
            id: 'data-transport',
            title: 'Angkutan',
            type: 'item',
            url: '/settings/angkutan',
            pusat: true
          }
        ]
      },

      {
        id : 'stats-report',
        title : 'Laporan',
        type : 'collapse',
        icon : 'feather icon-pie-chart',
        children: [
         /* {
            id : 'stats-customer',
            title : 'Statistik Customer',
            type : 'item',
            url : '/statistik/customer'
          },
          {
            id : 'stats-cabang',
            title : 'Statistik Cabang',
            type : 'item',
            url : '/statistik/cabang',
            pusat : true
          },*/
          {
            id : 'report-muatan-masuk',
            title : 'Laporan Muatan Masuk',
            type : 'item',
            url : '/report/muatan-masuk'
          },
          {
            id : 'report-order-bulanan',
            title : 'Laporan Muatan Keluar',
            type : 'item',
            url : '/report/muatan-keluar'
          },
          {
            id : 'report-survey-outlet',
            title : 'Laporan Coarri Codeco',
            type : 'item',
            url : '/report/report-coarri'
          }
        ]
      },

    
      
    ]
  }
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
