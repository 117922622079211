<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Statistik Cabang" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Cabang</label>
                        <div class="col-sm-9">
                            <ng-select [(ngModel)]="stats.id_cabang" (change)="selectCabang($event)" placeholder="Pilih Cabang"
                                [items]="data_cabang" bindLabel="nama" bindValue="id">
                                <ng-template ng-option-tmp let-item="item">
                                    <div class="line">{{item.nama}}</div>
                                    <div class="line"><small>({{item.no_telp}})</small></div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Email</label>
                        <div class="col-sm-9">
                            <input type="text" readonly class="form-control" [(ngModel)]="stats.email" />
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Alamat</label>
                        <div class="col-sm-9">
                            <input type="text" readonly class="form-control" [(ngModel)]="stats.alamat" />
                        </div>
                    </div>
                </div>

            </div>
        </app-card>

        <app-card cardTitle="Grafik Order 12 Bulan Terakhir" [options]="false">
            <div id="chartdiv" style="width: 100%; height: 285px"></div>
        </app-card>

        <app-card cardTitle="Data Transaksi 30 Hari Terakhir" [options]="false">
            <table class="table">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Kode Order</th>
                        <th>Tanggal</th>
                        <th>Nama Pasien</th>
                        <th class="text-right">Jumlah</th>
                        <th class="text-right">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.kode_order}}</td>
                        <td>{{item.tanggal | date:"dd/MM/yyyy"}}</td>
                        <td>{{item.nama_pasien}}</td>
                        <td class="text-right">{{item.jumlah | number}} item</td>
                        <td class="text-right">Rp.{{item.total | number}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td colspan="3"><strong>Total</strong></td>
                        <td class="text-right"><strong>{{getTotalJumlah() | number}} item</strong></td>
                        <td class="text-right"><strong>Rp.{{getTotalRp() | number}}</strong></td>
                    </tr>
                </tfoot>
            </table>
        </app-card>
    </div>
</div>