<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Kontrak Baging" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Kontrak Baging">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Gudang</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.warehouse_id"
                                                placeholder="Pilih Gudang" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jenis Barang</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.product_id" placeholder="Pilih Product"
                                                [items]="data_product" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.to_date" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Customer</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.customer_id"
                                                placeholder="Pilih Customer" [items]="data_customer" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>No. Voy/Flight</th>
                                            <th>Customer</th>
                                            <th>Coarri Codeco</th>
                                            <th>Gudang</th>
                                            <th>Tanggal</th>
                                            <th>Jenis Barang</th>
                                            <th>Unit</th> 
                                            <th class="text-right">Party</th>
                                            <th class="text-right">Penerimaan</th>
                                            <th class="text-right">Sisa Stock</th>
                                            <th class="text-center">Dibuat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{i+1}}</td>
                                            <td><a href='#'>{{item.ship_number}}</a></td>
                                            <td>{{item?.customer?.name || ''}}</td>
                                            <td>{{item.doc_label}}</td>
                                            <td>{{item?.warehouse?.name || ''}}</td>
                                            <td>{{item?.contract_date | date:'dd/MM/yyyy'}}</td>
                                            <td>{{item?.product?.name || ''}}</td>
                                            <td>{{item?.product?.unit || ''}}</td>
                                            <td class="text-right">{{item.party | number}}</td>
                                            <td class="text-right">{{item.party_nett | number}}</td>
                                            <td class="text-right">{{item.stock_qty | number}}</td>
                                            <td class="text-center">{{item?.created_by_user?.name || ''}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Tambah Kontrak Baging">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Gudang</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="bagging_contract.warehouse_id"
                                                placeholder="Pilih Gudang" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jenis Barang</label>
                                        <div class="col-sm-9">
                                            <ng-select 
                                                [(ngModel)]="bagging_contract.product_id" placeholder="Pilih Jenis Barang"
                                                [items]="data_product" bindLabel="name" bindValue="id">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="line">{{item.name}}</div>
                                                    <div class="line"><small>({{item.product_type ?
                                                            item.product_type.name : '-'}})</small></div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Customer</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="bagging_contract.customer_id"
                                            placeholder="Pilih Customer" [items]="data_customer" bindLabel="name"
                                            bindValue="id"></ng-select>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Coarri Codeco</label>
                                        <div class="col-sm-9">
                                            <ng-select 
                                                [(ngModel)]="bagging_contract.doc_code" placeholder="Pilih Dokumen Coarri Codeco"
                                                [items]="data_codes" bindLabel="description" bindValue="id">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="line">{{item.description}}</div>
                                                    <div class="line"><small>{{item.name}}</small></div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Voy/Flight</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="bagging_contract.ship_number" placeholder="Nomor Perjalanan" uppercase />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jenis Angkutan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="bagging_contract.transport_name" placeholder="Jenis Angkutan" uppercase />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Call Sign</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="bagging_contract.call_sign" placeholder="Kode Angkutan" uppercase />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Ref</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="bagging_contract.ref_number" placeholder="Nomor Referensi" uppercase />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Party {{getUnit()}}</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" type="text" [(ngModel)]="bagging_contract.party" currencyMask
                                            [options]="option_currency" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Masuk</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="bagging_contract.contract_date" type="date" class="form-control"
                                                placeholder="Tanggal Masuk">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="bagging_contract.description"
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button
                                        [disabled]="bagging_contract.party<=0 || !bagging_contract.ship_number || !bagging_contract.warehouse_id || !bagging_contract.product_id || !bagging_contract.customer_id || !bagging_contract.doc_code || !bagging_contract.call_sign || !bagging_contract.transport_name || !bagging_contract.ref_number"
                                        (click)="doSimpan()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Simpan
                                        </span>
                                    </button> &nbsp;
                                </div>
                            </div>
                        </div>

                        <br />

                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>