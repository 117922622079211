import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { days, roles } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-kunjungan-outlet',
  templateUrl: './kunjungan-outlet.component.html',
  styleUrls: ['./kunjungan-outlet.component.scss']
})
export class KunjunganOutletComponent implements OnInit {
  data_tap: any = []
  data_sf: any = []
  filter = {
    entity_id: null,
    user_id: null,
    from_date: this.go.formatDate(new Date()),
    end_date: this.go.formatDate(new Date()),
    page: 1
  }
  roles = roles

  isLoading = false

  items: any = []
  total_page = 0
  constructor(private go: GoService, private toast: ToastrService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.getDataTap()
    this.activatedRoute.queryParams.subscribe(param => {
      for (let key in param)
        if (key == 'entity_id' || key == 'user_id' || key == 'page')
          this.filter[key] = Number(param[key])
        else this.filter[key] = param[key]
      console.log(this.filter)
      if (this.filter.entity_id) this.doCari()
    })
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  selectTAP(ev) {
    if (ev) {
      this.filter.entity_id = ev.id
      this.getDataUsers()
    }
  }

  changePage(ev) {
    this.filter.page = Number(ev)
    this.doCari()
  }

  getDataUsers() {
    this.go.authHttp.get('users/list?role=' + this.roles.SALES_FORCE + '&entity_id=' + this.filter.entity_id)
      .subscribe((res: any) => {
        this.data_sf = res
      })
  }

  doRefresh() {
    this.filter.page = 1
    this.doCari()
  }

  doCari() {
    if (this.isLoading) return false
    this.isLoading = true
    let params = '?from_date=' + this.filter.from_date + '&end_date=' + this.filter.end_date
    params += this.filter.entity_id ? '&entity_id=' + this.filter.entity_id : ''
    params += this.filter.user_id ? '&user_id=' + this.filter.user_id : ''
    params += '&page=' + this.filter.page

    this.router.navigateByUrl('/sales-force/kunjungan' + params)

    this.go.authHttp.get('sales-force/visits/v2' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res.data
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Kunjungan SF')
      })
  }

  doDownload() {
    this.isLoading = true
    let params = '?from_date=' + this.filter.from_date + '&end_date=' + this.filter.end_date
    params += this.filter.entity_id ? '&entity_id=' + this.filter.entity_id : ''
    params += this.filter.user_id ? '&user_id=' + this.filter.user_id : ''
    this.go.downloadFile('sales-force/visits/download' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  reset() {
    this.items = []
    this.filter.entity_id = null
    this.filter.user_id = null
  }

  isDisabled() {
    for (let key in this.filter) {
      if (!this.filter[key]) return true
    }
    return false
  }

  ngOnInit(): void {
  }

}
