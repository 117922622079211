import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { days, roles } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {
  data_tap: any = []
  data_sf: any = []
  filter = {
    entity_id: null,
    user_id: null,
    date: this.go.formatDate(new Date())
  }
  roles = roles

  isLoading = false

  items: any = []
  constructor(private go: GoService, private toast: ToastrService) {
    this.getDataTap()
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  selectTAP(ev) {
    if (ev) {
      this.filter.entity_id = ev.id
      this.getDataUsers()
    }
  }

  getDataUsers() {
    this.go.authHttp.get('users/list?role=' + this.roles.SALES_FORCE + '&entity_id=' + this.filter.entity_id)
      .subscribe((res: any) => {
        this.data_sf = res
      })
  }

  mode_map = ''
  initLng = 98.68073699817948
  initLat = 3.579070780816168
  doCari() {
    this.isLoading = true
    let params = this.filter.date ? '?date=' + this.filter.date : ''
    params += this.filter.entity_id ? '&entity_id=' + this.filter.entity_id : ''
    params += this.filter.user_id ? '&user_id=' + this.filter.user_id : ''
    this.go.authHttp.get('sales-force/tracking' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.mode_map = res.mode
        this.items = []
        if (res.data) {
          setTimeout(() => {
            this.initLat = res.data[0].latitude
            this.initLng = res.data[0].longitude
            for (let item of res.data) {
              let dt = new Date(item.createdAt)
              let time = (dt.getHours()>=10 ? dt.getHours() : '0' + dt.getHours()) + ':' + (dt.getMinutes()>=10 ? dt.getMinutes() : '0' + dt.getMinutes())
              this.items.push({
                longitude: item.longitude,
                latitude: item.latitude,
                content: '<strong>'+ item.user.name + '</strong><br />' + time
              })
            }
          }, 100)
        }
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Kunjungan SF')
      })
  }

  reset() {
    this.items = []
    this.filter.entity_id = null
    this.filter.user_id = null
  }

  isDisabled() {
    if (!this.filter.entity_id) return true
    if (!this.filter.date) return true
    return false
  }

  ngOnInit(): void {
  }

}
