<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Preview Sticker</h4>
  </div>
  <div id="sticker" class="modal-body">
    <div  class="sticker-container">
      <div class="left-content">
        <table>
          <tr>
            <td class="td-id-order">ID ORDER : </td>
            <td>{{data.kode_order}}</td>
            <td>/</td>
            <td>{{data.tanggal | date:"dd/MM/yyyy"}}</td>
          </tr>
          <tr>
            <td colspan="2">
              Dokter :<br>
              {{order.customer | uppercase}}
            </td>
            <td colspan="2">
              Pasien :<br>
              {{data.nama_pasien | uppercase}}
            </td>
          </tr>
        </table>
        
      </div>
  
      <div class="right-content">
        <img class="logo-kezora" src="./assets/images/logo-kezora-round.png" />
      </div>
  
      <div style="clear: both"></div>
      <p style="text-align: center; text-decoration: underline; font-weight: bold; margin: 0px;">
        Item Order
      </p>
  
      <table class="detail-order" style="width: 100%;">
        <tr *ngFor="let item of detail_order">
          <td style="width: 35%">{{item.nama_product}}</td>
          <td style="width: 20%">{{item.bahan_product}}</td>
          <td style="width: 10%">{{item.warna}}</td>
          <td style="width: 35%">
            <span *ngFor="let itm of item.posisi | sortBy : ['pos']; let i = index">{{i > 0 ? ', ' : ''}}{{itm.pos}}</span>
          </td>
        </tr>
      </table>
      
  
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-simple btn-danger" (click)="tutup()">Tutup</button>
    <button type="button" class="btn btn-simple btn-success" (click)="printSticker()">Print</button>
  </div>