export var URL_ORIGIN = 'https://api.dumaiboneperkasa.com/'
export var STATUS_ORDER = [
   
]

export var OPTION_CURRENCY_MASK = { prefix: '', precision :0, thousands: '.', decimal: ',', allowNegative : false, align : 'left', nullable: true}

export var STATUS_INVOICE = [
    {
        id : 0,
        text : 'Belum Ditagih'
    },
    {
        id : 1,
        text : 'Ditagih'
    },
    {
        id : 2,
        text : 'Lunas'
    }
]

export var PRIVILEGES = [
    '', 'Super Admin', 'Admin Pusat', 'Admin TAP', 'Sales Force'
]

export var ENTITY_TYPE = [
    {
        id : 1,
        value: 'Kantor'
    },
    {
        id: 2,
        value: 'Gudang'
    }
]

export var privileges= [
    { id: 1, text: 'Super Admin' },
    { id : 2, text : 'Admin Kantor'},
    { id : 3, text : 'Admin Gudang'}
  ]

export var entity_types = ['', 'Kantor Pusat', 'Gudang', 'Customer']

export var transaction_type_id = {
    TAMBAH_STOCK_PUSAT : 1,
    DISTRIBUSI_STOCK_TAP : 2,
    DISTRIBUSI_STOCK_SF : 3,
    JUAL_TAP_OUTLET : 4,
    JUAL_SF_OUTLET : 5,
    RECEIVE_SF : 6,
    RETURN_STOCK_TAP_PUSAT : 7,
    RETURN_STOCK_TAP_TAP : 8,
    RETURN_STOCK_SF_TAP : 9,
    RETURN_STOCK_SF_SF : 10
}


export var roles = {
    SUPER_ADMIN : 1,
    ADMIN_PUSAT : 2,
    ADMIN_TAP : 3,
    SALES_FORCE : 4
}
export var days = [
    {id : 1, name : 'Senin'},
    {id : 2, name : 'Selasa'},
    {id : 3, name : 'Rabu'},
    {id : 4, name : 'Kamis'},
    {id : 5, name : 'Jumat'},
    {id : 6, name : 'Sabtu'}
]

export var product_expired_id = {
    1 : '3 Days',
    2 : '5 Days',
    3 : '7 Days',
    4 : '30 Days',
    5 : '> 30 Days'
}

export var months = {
    1 : 'Januari',
    2 : 'Februari',
    3 : 'Maret',
    4 : 'April',
    5 : 'Mei',
    6 : 'Juni',
    7 : 'Juli',
    8 : 'Agustus',
    9 : 'September',
    10 : 'Oktober',
    11 : 'November',
    12 : 'Desember'
}

export var PALKA = [
    {
        id: 1,
        value: 'Palka 1'
    },
    {
        id: 2,
        value: 'Palka 2'
    },
    {
        id: 3,
        value: 'Palka 3'
    },
    {
        id: 4,
        value: 'Palka 4'
    },
    {
        id: 5,
        value: 'Palka 5'
    }
]