import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';
import { ToastService } from 'src/app/theme/shared/components/toast/toast.service';

@Component({
  selector: 'app-data-customer',
  templateUrl: './data-customer.component.html',
  styleUrls: ['./data-customer.component.scss']
})
export class DataCustomerComponent implements OnInit {
  data : any = []
  page = 0
  total = 0
  length = 0
  total_page = 0
  isLoading = false
  current_page = 1
  filter = {
    parent_id : null,
    regency_id : null,
    search : ''
  }
  data_tap : any = []
  data_regencies : any = []
  constructor(private go : GoService, private toast : ToastService, private toastCtrl : ToastrService, private router : Router, private activateRoutes : ActivatedRoute) { 
    this.getRegencies()
    this.getTAP()
    
    this.activateRoutes.queryParams.subscribe(param=>{
      if(param.page || param.page == 0){
        this.page = param.page
        this.current_page = Number(this.page) + 1
      }
      if(param.search)
        this.filter.search = param.search

      if(param.parent_id) this.filter.parent_id = Number(param.parent_id)
      if(param.regency_id) this.filter.regency_id = Number(param.regency_id)

      this.getData() 
    })
  }

  getData(){
    this.isLoading = true
    let params = ''
    for(let key in this.filter)
      if(this.filter[key]) params += `&${key}=${this.filter[key]}`
    this.go.authHttp.get('entities/list?entity_type_id=3&page=' + this.page + '' + params)
    .subscribe((res : any)=>{
      this.data = res.data
      this.total = res.total
      this.isLoading = false

      this.length = this.data.length
      this.total_page = this.total
    }, eror=>{
      this.isLoading  = false
      this.toastCtrl.error('Data customer gagal ditampilkan' , 'Data customer')
    })
  }

  doCari(){
    this.page = 0
    let params = ''
    for(let key in this.filter)
      if(this.filter[key]) params += `&${key}=${this.filter[key]}`
    this.router.navigateByUrl('/customer/data?page=' + this.page + decodeURIComponent(params))
  }

  doDownload() {
    if(this.isLoading) return
    this.isLoading = true
    let params = ''
    for(let key in this.filter)
      if(this.filter[key]) params += `&${key}=${this.filter[key]}`

    this.go.downloadFile('entities/download?entity_type_id=3' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      }, error=>{
        this.isLoading = false
        this.toastCtrl.error('Data customer gagal didownload' , 'Data customer')
      })
  }

  changePage(ev){
    this.page = ev - 1
    let params = ''
    for(let key in this.filter)
      if(this.filter[key]) params += `&${key}=${this.filter[key]}`
    this.router.navigateByUrl('/customer/data?page=' + this.page + decodeURIComponent(params))
  }

  hapus(id){
    this.go.showConfirm('Data customer', 'Apakah anda yakin akan menghapus data ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('entities/' + id)
        .subscribe((res: any)=>{
          this.toast.toast({uid: 'toastSuccess', delay: 3000, title : 'Data customer', content : 'Data customer berhasil dihapus'})

          this.getData()
        })
      }
    })
  }

  getRegencies(){
    this.go.authHttp.get('entities/regencies')
    .subscribe((res : any)=>{
      this.data_regencies = res
    })
  }

  getTAP(){
    this.go.authHttp.get('entities?entity_type_id=2')
    .subscribe((res : any)=>{
      this.data_tap = res
    })
  }

  ngOnInit(): void {
  }

}
