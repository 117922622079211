<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Penerimaan Barang" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Tambah Transaksi">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Gudang</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectGudang($event)"
                                                [(ngModel)]="transaction.warehouse_id" placeholder="Pilih Gudang"
                                                [items]="data_gudang" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">No. Voy/Flight</label>
                                        <div class="col-sm-9">
                                            <ng-select [disabled]="isLoadingstock" (change)="selectKapal($event)"
                                                [(ngModel)]="transaction.contract_id" placeholder="Pilih No. Voy/Flight"
                                                [items]="data_contracts" bindLabel="ship_number" bindValue="id">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="line">{{item.ship_number}}</div>
                                                    <div class="line"><small>({{item?.product?.name || '-'}})</small>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jenis Barang</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [value]="product?.name || ''" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Party
                                            {{getUnit()}}</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [(ngModel)]="transaction.party"
                                                currencyMask [options]="option_currency" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Penerimaan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [(ngModel)]="transaction.party_nett"
                                                currencyMask [options]="option_currency" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" placeholder="Keterangan/Catatan"
                                                [(ngModel)]="transaction.description" type="text" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jenis Kemasan</label>
                                        <div class="col-sm-9">
                                           <select class="form-control" [(ngModel)]="transaction.container">
                                            <option *ngFor="let item of containers" [value]="item.id">{{item.title}}</option>
                                           </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Kemasan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" placeholder="Nomor Seri Kemasan atau Kontainer"
                                                [(ngModel)]="transaction.package_number" type="text" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal</label>
                                        <div class="col-sm-9">
                                            <input (change)="getBaggageMasuk()" [(ngModel)]="transaction.actual_date"
                                                type="date" class="form-control">
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table" *ngIf="transaction.contract_id">
                                        <tr>
                                            <th colspan="4">Tanggal</th>
                                            <th class="text-center">Jumlah Trip</th>
                                            <th class="text-center">Total Netto</th>
                                            <th class="text-center">Detail</th>
                                        </tr>
                                        <tr>
                                            <td colspan="4">{{transaction.actual_date | date:"dd/MM/yyyy"}}</td>
                                            <td class="text-center">{{jumlahTrip}}</td>
                                            <td class="text-center">{{totalBaggage | number}} {{product?.unit}}</td>
                                            <td class="text-center">
                                                <input type="checkbox" [(ngModel)]="showAll" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Angkutan</th>
                                            <th>Truk</th>
                                            <th>Palka</th>
                                            <th class="column-number">Brutto</th>
                                            <th class="column-number">Tarra</th>
                                            <th class="column-number">Netto</th>
                                            <th></th>
                                        </tr>

                                        <tr *ngFor="let item of baggage_receipts; let i = index">
                                            <td>
                                                <ng-select [(ngModel)]="item.transport_id" placeholder="Pilih Angkutan"
                                                    [items]="data_angkutan" bindLabel="name" bindValue="id"></ng-select>
                                            </td>
                                            <td>
                                                <ng-select [(ngModel)]="item.transport_truck_id"
                                                    placeholder="Pilih Truk" [items]="getTruk(item)"
                                                    bindLabel="plate_number" bindValue="id"></ng-select>
                                            </td>
                                            <td>
                                                <select class="form-control" [(ngModel)]="item.palka">
                                                    <option *ngFor="let itm of palka" value="{{itm.id}}">{{itm.value}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input [(ngModel)]="item.brutto" type="text" class="form-control"
                                                    currencyMask [options]="option_currency" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="item.tarra" type="text" class="form-control"
                                                    currencyMask [options]="option_currency" />
                                            </td>
                                            <td>
                                                <input readonly="text" class="form-control"
                                                    [value]="item.brutto - item.tarra | number" />
                                            </td>
                                            <td>
                                                <button (click)="hapusTruk(i)" class="btn btn-outline-danger">
                                                    <i class="feather icon-trash"></i>
                                                </button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="7">
                                                <button (click)="tambahAngkutan()" class="btn btn-outline-dark">
                                                    + Barang Masuk
                                                </button>
                                            </td>
                                        </tr>

                                        <ng-container *ngIf="showAll">
                                            <tr *ngFor="let item of data_baggages">
                                                <td>{{item?.transport?.name || ''}}</td>
                                                <td>{{item?.transport_truck?.plate_number || ''}}</td>
                                                <td>{{palka[item.palka - 1].value}}</td>
                                                <td>{{item.brutto | number}}</td>
                                                <td>{{item.tarra | number}}</td>
                                                <td>{{item.netto | number}}</td>
                                                <td></td>
                                            </tr>
                                        </ng-container>


                                    </table>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doSimpan()" [disabled]="isDisabled()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Simpan
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger m-2" type="button">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>

                        <br />

                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>