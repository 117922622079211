import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoService } from '../../services/go.service';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'app-print-label',
  templateUrl: './print-label.component.html',
  styleUrls: ['./print-label.component.scss']
})
export class PrintLabelComponent implements OnInit {
  @Input() public data;
  order : any = {}
  detail_order : any = []
  constructor(private modalRef :NgbActiveModal, private go : GoService, private printService : PrintService) { 
    
   // this.order = this.data
  }

  ngOnInit() {
    this.go.authHttp.get('order/data-order-invoice/' + this.data.id)
    .subscribe((res : any)=>{
      this.order = res.order
      for(let item of res.details){
        var selected = this.detail_order.filter(it => it.warna == item.warna && it.id_product == item.id_product)
        if(selected.length>0){
          var index = this.detail_order.indexOf(selected[0])
          this.detail_order[index].posisi.push({pos : item.posisi})
        }else{
          var data = {
            id_product : item.id_product,
            nama_product : item.nama_product,
            bahan_product : item.bahan_product,
            warna : item.warna,
            posisi : [{pos : item.posisi}]
          }
          this.detail_order.push(data);
          
        }
      }
      //this.detail_order = res.details
    })
  }
  
  tutup(){
    this.modalRef.close();
  }

  printSticker(){
    this.printService.printSticker('sticker')
  }

}
