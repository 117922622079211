<div class="row">
    <div class="col-md-12" *ngFor="let item of visits">
        <app-card cardTitle="Kunjungan Outlet Sales Force" [options]="false">
            <div class="row">
                <div class="col-md-4">
                    <div class="image">
                        <img [src]="urls + 'images/' + item.image" />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Outlet</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" readonly [value]="item.outlet.name" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Waktu Check-In</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" readonly
                                value="{{item.createdAt | date:'dd/MM/yyyy HH:mm:ss'}}" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Waktu Check-Out</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" readonly
                                value="{{item.checkoutAt | date:'dd/MM/yyyy HH:mm:ss'}}" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Kondisi Outlet</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" readonly
                                value="{{item.status ? 'Buka' : 'Tutup'}}" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" readonly value="{{item.description}}" />
                        </div>
                    </div>

                    <div class="map-container" style="min-height:200px">
                        <app-map-container [mode]="mode_map" [markers]="items" [longitude]="initLng"
                            [latitude]="initLat" [zoom]="20"></app-map-container>
                    </div>
                </div>
            </div>

            <br /><br />
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center">No.</th>
                                <th>Produk</th>
                                <th>Keterangan</th>
                                <th class="text-right">Qty</th>
                                <th class="text-right">Harga</th>
                                <th class="text-right">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let trans of item.transactions; let i = index">
                                <td class="text-center">{{i+1}}</td>
                                <td>{{trans.product.name}}</td>
                                <td>{{trans.description}}</td>
                                <td class="text-right">{{trans.qty | number}}</td>
                                <td class="text-right">Rp.{{trans.price | number}}</td>
                                <td class="text-right">Rp.{{trans.price * trans.qty | number}}</td>
                            </tr>
                            <tr>
                                <td class="text-center"></td>
                                <td colspan="4"><strong>Total</strong></td>

                                <td class="text-right"><strong>Rp.{{getTotal(item.transactions) | number}}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </app-card>
    </div>
</div>