<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="{{pegawai.id == 0 ? 'Tambah User' : 'Edit User'}}" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Username</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="pegawai.username" type="text" class="form-control"
                                placeholder="Username/Kode Khusus Sales Force">
                        </div>
                    </div>
                </div>
                
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Lengkap</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="pegawai.name" type="text" class="form-control"
                                placeholder="Nama lengkap user">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Email</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="pegawai.email" type="email" class="form-control"
                                placeholder="Email user">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Hp</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="pegawai.phone_number" type="tel" class="form-control"
                                placeholder="Nomor Hp user">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Password</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="pegawai.password" type="text" class="form-control"
                                placeholder="Password default user">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Entitas</label>
                        <div class="col-sm-9">
                            <select class="form-control" [(ngModel)]="pegawai.entity_id">
                                <option [value]="null"> Pilih Kantor </option>
                                <option [value]="item.id" *ngFor="let item of data_cabang">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Hak Akses</label>
                        <div class="col-sm-9">
                            <select class="form-control" [(ngModel)]="pegawai.role">
                                <option [value]="item.id" *ngFor="let item of privileges">
                                    {{item.text}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Foto User</label>
                        <div class="col-sm-9">
                            <div class="custom-file">
                                <input accept="image/*" (change)="getImage($event)" type="file" class="custom-file-input" id="validatedCustomFile" required>
                                <label class="custom-file-label" for="validatedCustomFile">Choose file...</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="col-sm-3"> </div>
                    <div class="col-sm-9">
                        <button [disabled]="isDisabled()" (click)="doSimpan()" class="btn  btn-primary m-2"
                            type="button">
                            <span *ngIf="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status"></span>
                                Loading...
                            </span>
                            <span *ngIf="!isLoading">
                                Simpan
                            </span>
                        </button>

                        <button (click)="location.back()" class="btn btn-danger m-2" type="button">
                            Kembali
                        </button>
                    </div>
                </div>
            </div>


        </app-card>
    </div>
</div>