import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { months, product_expired_id, roles, transaction_type_id } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document: any
@Component({
  selector: 'app-sales-plan-outlet',
  templateUrl: './sales-plan-outlet.component.html',
  styleUrls: ['./sales-plan-outlet.component.scss']
})
export class SalesPlanOutletComponent implements OnInit {
  data_tap: any = []
  data_product: any = []
  expired_data = []
  filter = {
    entity_id: null,
    product_type_id: null,
    month: (new Date()).getMonth() + 1,
    year: (new Date()).getFullYear(),
    page: 0,
    keyword: ''
  }

  upload = {
    entity_id: null,
    product_type_id: null,
    month: (new Date()).getMonth() + 1,
    year: (new Date()).getFullYear(),
    file: null
  }

  roles = roles

  isLoading = false
  isLoading2 = false

  items: any = []

  months = []
  years = [
    { id: 2022, value: 2022 },
    { id: 2023, value: 2023 },
    { id: 2024, value: 2024 },
    { id: 2025, value: 2025 }
  ]

  current_page = 1
  total_page = 0

  constructor(private go: GoService, private toast: ToastrService, private router: Router, private activateRoutes: ActivatedRoute) {

    this.getExpired()

    for (let key in months) {
      this.months.push({
        id: Number(key),
        value: months[key]
      })
    }
    this.getDataTap()
    this.getDataProduct()

    this.activateRoutes.queryParams.subscribe(param => {
      if (param.page) {
        this.filter.page = param.page
        this.current_page = Number(this.filter.page) + 1
      }
      if (param.entity_id) {
        this.filter.entity_id = Number(param.entity_id)
      }

      if (param.product_type_id) {
        this.filter.product_type_id = Number(param.product_type_id)
      }
      if (param.month) this.filter.month = Number(param.month)
      if (param.year) this.filter.year = Number(param.year)
      if (param.keyword) this.filter.keyword = param.keyword


      if (this.filter.entity_id) {
        console.log('Gotcha')
        this.getData()
      }

    })
  }

  getExpired() {
    this.go.authHttp.get('expired-periode-product')
      .subscribe((res: any) => {
        this.expired_data = res
      })
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  getDataProduct() {
    this.go.authHttp.get('product-types')
      .subscribe((res: any) => {
        this.data_product = res.data
      })
  }

  doCari() {
    this.filter.page = 0
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')
    this.router.navigateByUrl('/report/sales-plan-outlet?' + params)
  }


  getData() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key]) params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('report/sales-plan?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res.data
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Report Sales Plan Outlet')
      })
  }


  doDownload() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key]) params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')

    this.go.downloadFile('report/sales-plan/download?' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  reset() {
    this.filter.entity_id = null
    this.filter.product_type_id = null
    this.filter.month = (new Date().getMonth()) + 1
    this.filter.year = (new Date()).getFullYear()
    this.filter.keyword = ''
  }

  changePage(ev) {
    console.log(ev)
    this.filter.page = ev - 1
    let params_ar = []
    for (let key in this.filter) {
      params_ar.push(key + '=' + this.filter[key])
    }
    var params = params_ar.join('&')
    this.router.navigateByUrl('/report/sales-plan-outlet?' + params)
  }

  isDisabled() {
    if (!this.filter.entity_id) return true
    if (!this.filter.month) return true
    if (!this.filter.year) return true
    if (!this.filter.product_type_id) return true
    return false
  }

  isDisabled2() {
    if (!this.upload.entity_id) return true
    if (!this.upload.month) return true
    if (!this.upload.year) return true
    if (!this.upload.product_type_id) return true
    if (!this.upload.file) return true
    return false
  }

  getTotal() {
    let total = 0
    for (let item of this.items) total += item.qty * item.price

    return total
  }

  getValues(data_array, key) {
    var total = 0
    if (key == 'total') {
      for (let itm of data_array) total += Number(itm.qty)
    } else {
      for (let itm of data_array.filter(it => it.expired_id == key)) total += Number(itm.qty)
    }
    return total
  }

  getProgress(data_array1, data_array2, key) {
    var total1 = this.getValues(data_array1, key)
    var total2 = this.getValues(data_array2, key)

    var progress = 0
    if (total1 == 0) progress = 0
    else if (total2 == 0) progress = 0
    else progress = (total2 / total1) * 100

    return progress.toFixed(2) + '%'
  }

  ngOnInit(): void {
  }

  doUpload() {
    this.isLoading2 = true

    var data = new FormData()
    data.append('entity_id', this.upload.entity_id + '')
    data.append('product_type_id', this.upload.product_type_id + '')
    data.append('month', this.upload.month + '')
    data.append('year', this.upload.year + '')
    data.append('fileExcel', this.fileExcel)

    this.go.uploadFile('report/sales-plan/export', data)
      .subscribe((res: any) => {
        if (res.body) {
          this.isLoading2 = false
          this.upload.file = null
          this.toast.success('Data sales plan berhasil diupload', 'Sales Plan')
        }
      }, error => {
        this.toast.error('Data sales plan gagal ditambahkan', 'Sales Plan')
        this.isLoading2 = false
      })
  }

  fileExcel: any = null
  getFiles(ev) {
    if (ev.target.files) {
      this.fileExcel = ev.target.files[0]
    }
  }

  downloadFormat() {
    this.go.downloadFile('xls/download-template/format-sales-plan')
      .subscribe((res: any) => {
        let fileName = 'template-sales-plan.xlsx'
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }
}
