<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Kunjungan Outlet Sales Force" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                        <div class="col-sm-9">
                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sales Force</label>
                        <div class="col-sm-9">
                            <ng-select [(ngModel)]="filter.user_id" placeholder="Pilih Sales Force" [items]="data_sf"
                                bindLabel="name" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                placeholder="Tanggal Mulai">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="filter.end_date" type="date" class="form-control"
                                placeholder="Tanggal Akhir">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button [disabled]="!(filter.entity_id && filter.from_date && filter.end_date)" (click)="doRefresh()"
                        class="btn btn-success">
                        <span *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm" role="status"></span>
                            Loading...
                        </span>
                        <span *ngIf="!isLoading">
                            Cari
                        </span>
                    </button> &nbsp;
                    <button (click)="doDownload()" class="btn btn-info">
                        <span *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm" role="status"></span>
                            Loading...
                        </span>
                        <span *ngIf="!isLoading">
                            Download Excel
                        </span>
                    </button> &nbsp;
                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12">
                    <table class="table" style="font-size:12px">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Check-In</th>
                                <th>Check-Out</th>
                                <th>Nama Outlet</th>
                                <th>Kecamatan</th>
                                <th>Owner</th>
                                <th>Sales Force</th>
                                <th>Kondisi Outlet</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of items; let i = index" [ngClass]="{'not-checkout' : !item.checkoutAt}">
                                <td>{{i+1 + ((filter.page -1) * 20)}}</td>
                                <td>{{item.createdAt | date:"dd/MM/yyyy HH:mm"}}</td>
                                <td>{{item.checkoutAt | date:"dd/MM/yyyy HH:mm"}}</td>
                                <td>{{item.outlet ? item.outlet.name : '-'}}</td>
                                <td>{{item.outlet.regency ? item.outlet.regency.name : '-'}}</td>
                                <td>{{item.outlet ? item.outlet.owner : '-'}}</td>
                                <td>{{item.sales ? item.sales.name : '-'}}</td>
                                <td>{{item.status ? 'Buka' : 'Tutup'}}</td>
                                <td class="text-center">
                                    {{item.transactions.length>0 ? 'Ada Transaksi' : 'Tidak Ada Transaksi'}}
                                </td>
                                <td>
                                    <a routerLink="detail/{{item.id}}">Detail</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination [collectionSize]="total_page" [pageSize]="20" [(page)]="filter.page" (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination></div>
            </div>
        </app-card>
    </div>
</div>