import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { days, roles, transaction_type_id } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document: any
@Component({
  selector: 'app-lihat-stock',
  templateUrl: './lihat-stock.component.html',
  styleUrls: ['./lihat-stock.component.scss']
})
export class LihatStockComponent implements OnInit {
  data_tap: any = []
  data_sf: any = []

  filter: any = {
    page: 1,
    paginate : 10,
    warehouse_id: null,
    product_type_id: null
  }
  isLoading = false

  items: any = []

  total_page = 0
  roles = roles

  data_product: any = []

  constructor(private go: GoService, private toast: ToastrService, private activateRoutes: ActivatedRoute, private router: Router) {
    this.getDataTap()
    this.getDataProduct()

    this.activateRoutes.queryParams.subscribe((param: any) => {
      if (param.page) {
        for (let key in param) {
          if (param[key])
            this.filter[key] = Number(param[key])
        }

        this.getData()
      }else this.getData()
    })

  }

  getDataProduct() {
    this.go.authHttp.get('product-types')
      .subscribe((res: any) => {
        this.data_product = res.data
      })
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }


  getData() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key]) {
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('stocks/list?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res.list
        this.total_page = res.total
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Lihat Stock')
      })
  }


  doCari() {
    let params = ''

    this.filter.page = 1

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key])
        params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')
    this.router.navigateByUrl('/transaksi/lihat-stock?' + params)
  }

  changePage(ev) {
    let params = ''
    this.filter.page = ev
    let params_ar = []
    for (let key in this.filter) {
      if(this.filter[key])
        params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')
    this.router.navigateByUrl('/transaksi/lihat-stock?' + params)
  }

  doDownload() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key]) {
        params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.go.downloadFile('stocks/download?' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  reset() {
    this.items = []
    this.filter.page = 1
    this.filter.warehouse_id = null
    this.filter.product_type_id = null
  }

  isDisabled() {
    return false
  }

  getTotal() {
    let total = 0
    for (let item of this.items) total += item.qty * item.price

    return total
  }

  ngOnInit(): void {
  }

}
