import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { URL_ORIGIN } from '../app.variables';
import { KonfirmasiComponent } from '../components/konfirmasi/konfirmasi.component';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GoService {
  urls = URL_ORIGIN
  constructor(private http : HttpClient, private userService : UserService, private modal : NgbModal) { }

  showConfirm(title, message){
    let modal = this.modal.open(
      KonfirmasiComponent
    )
    modal.componentInstance.data = {title : title, message : message}
    return modal
  }

  downloadFile : any = (url : any)=>{
      const token = this.userService.getToken();
      const httpOptions = {
        headers : new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + token
        }),
        responseType : 'arraybuffer' as 'json',
        observe : 'response'  as 'body'
      }
      return this.http.get(this.urls + url, httpOptions);
  }

  public authHttp : any = {
    get : (url)=>{
      const token = this.userService.getToken();
      const httpOptions = {
        headers : new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + token
        })
      }
      return this.http.get(this.urls + url, httpOptions);
    } ,
    put : (url, body)=>{
      const token = this.userService.getToken();
      const httpOptions = {
        headers : new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + token
        })
      }
      return this.http.put(this.urls + url, body, httpOptions);
    },
    delete : (url)=>{
      const token = this.userService.getToken()
      const httpOptions = {
        headers : new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + token
        })
      }
      return this.http.delete(this.urls + url, httpOptions);
    },
    post : (url, body)=>{
      const token = this.userService.getToken();
      const httpOptions = {
        headers : new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + token
        })
      }
      return this.http.post(this.urls + url, body, httpOptions);
    }
  }

  public HTTP : any = {
    get : (url)=>{
      return this.http.get(this.urls + url);
    },
    post : (url, body)=>{
      return this.http.post(this.urls + url, body);
    }
  }

  uploadFile(url, data){
    var token = this.userService.getToken()
    const httpOptions = {
      headers : new HttpHeaders({
        'Authorization': 'Bearer ' + token
      }),
      reportProgress : true
    }
    var req = new HttpRequest('POST', this.urls + url, data, httpOptions)
    return this.http.request(req)
  }

 formatDate(date) {
    var dt = new Date(date)
    return dt.getFullYear() + '-' + (dt.getMonth() < 9 ? '0' : '') + (dt.getMonth() + 1) + '-' + (dt.getDate() < 10 ? '0' : '') + dt.getDate();
}


}
