import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { days, roles, transaction_type_id } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

declare var window: any
declare var document : any
@Component({
  selector: 'app-report-order',
  templateUrl: './report-order.component.html',
  styleUrls: ['./report-order.component.scss']
})
export class ReportOrderComponent implements OnInit {
  data_tap: any = []
  data_product: any = []
  data_stocks: any = []
  data_stocks_sf: any = []
  data_sf: any = []
  days = days
  filter = {
    entity_id: null,
    sales_id: null,
    product_id: null,
    from_date: this.go.formatDate(new Date()),
    end_date: this.go.formatDate(new Date()),
    transaction_type_id: transaction_type_id.JUAL_SF_OUTLET + ',' + transaction_type_id.JUAL_TAP_OUTLET
  }
  roles = roles

  isLoading = false

  items: any = []

  transaction = {
    vendor_id: null,
    customer_id: null,
    entity_id: null,
    sales_id: null,
    product_id: null,
    opening_qty: 0,
    qty: null,
    description: '',
    transaction_type_id: [transaction_type_id.JUAL_SF_OUTLET, transaction_type_id.JUAL_TAP_OUTLET],
    stock_id: null
  }
  constructor(private go: GoService, private toast: ToastrService) {
    this.getDataTap()
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  getDataProduct() {
    this.data_product = []
    for (let item of this.data_stocks) {
      this.data_product.push(item.product)
    }
  }

  selectTAP(ev) {
    if (ev) {
      this.filter.entity_id = ev.id
      this.getDataUsers(ev.id)
    }
  }

  isLoadingstock = false
  selectTAP2(ev) {
    if (ev) {
      this.transaction.vendor_id = ev.id
      this.transaction.entity_id = ev.id
      this.transaction.opening_qty = 0

      this.getStocks()
      this.getDataUsers(ev.id)
    }
  }

  selectSF2(ev) {
    if (ev) {
      this.transaction.sales_id = ev.id
      this.getStocksSF()
    }
  }

  getDataUsers(entity_id) {
    this.go.authHttp.get('users/list?role=' + this.roles.SALES_FORCE + '&entity_id=' + entity_id)
      .subscribe((res: any) => {
        this.data_sf = res
      })
  }

  getStocks() {
    this.data_stocks = []

    this.isLoadingstock = true
    this.go.authHttp.get('transactions/stocks?entity_id=' + this.transaction.entity_id)
      .subscribe((res: any) => {
        this.data_stocks = res
        this.isLoadingstock = false
        this.getOpeningQty()
        this.getDataProduct()
      })
  }

  getStocksSF() {
    this.data_stocks_sf = []

    this.isLoadingstock = true
    this.go.authHttp.get('transactions/stocks?sales_id=' + this.transaction.sales_id)
      .subscribe((res: any) => {
        this.data_stocks_sf = res
        this.isLoadingstock = false
      })
  }

  selectProduct(ev) {
    if (ev) {
      this.transaction.product_id = ev.id
      this.getOpeningQty()
    }
  }

  getOpeningQty() {
    this.transaction.opening_qty = 0
    var selected = this.data_stocks.filter(it => it.product_id == this.transaction.product_id)
    if (selected.length > 0) {
      this.transaction.stock_id = selected[0].id
      this.transaction.opening_qty = selected[0].qty
    }
  }

  doSimpan() {
    if (this.isLoading) return false
    if (this.transaction.qty > this.transaction.opening_qty) {
      this.toast.warning('Qty > sisa stock di TAP', '')
      return false
    }
    this.isLoading = true

    this.go.authHttp.post('transactions/create', this.transaction)
      .subscribe((res: any) => {
        this.isLoading = false
        this.transaction.product_id = null
        this.transaction.qty = null
        this.transaction.opening_qty = 0
        this.transaction.description = ''
        this.toast.success('Data stock berhasil ditambahkan', 'Distribusi')
        this.getStocks()
        this.getStocksSF()
      }, error => {
        console.log(error)
        this.isLoading = false
        this.toast.error(error.error.message, 'Distribusi')
      })
  }

  getSisaStockSF() {
    let selected = this.data_stocks_sf.filter(it => it.sales_id == this.transaction.sales_id && it.product_id == this.transaction.product_id)
    return selected.length > 0 ? selected[0].qty : 0
  }

  doCari() {
    this.isLoading = true
    let params = ''
    
    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key]){
        if(key == 'entity_id') params_ar.push('vendor_id=' + this.filter[key])
        else params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('transactions/list?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Outlet SF')
      })
  }

  doDownload() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for (let key in this.filter) {
      if (this.filter[key]){
        if(key == 'entity_id') params_ar.push('vendor_id=' + this.filter[key])
        else params_ar.push(key + '=' + this.filter[key])
      }
    }
    params = params_ar.join('&')
    this.go.downloadFile('transactions/download?' + params)
      .subscribe((res: any) => {
        let fileName = res.headers.get('Filename')
        this.isLoading = false
        let blob = new Blob([res.body], { type: 'application/ms-excel' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
      })
  }

  reset() {
    this.items = []
    this.filter.entity_id = null
    this.filter.product_id = null
    this.filter.from_date = null
    this.filter.end_date = null
  }

  isDisabled() {
    return false
  }

  getTotal() {
    let total = 0
    for (let item of this.items) total += item.qty * item.price

    return total
  }

  ngOnInit(): void {
  }

}
