import { Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-konfirmasi',
  templateUrl: './konfirmasi.component.html',
  styleUrls: ['./konfirmasi.component.scss']
})
export class KonfirmasiComponent implements OnInit {
  @Input() public data;
  constructor(public dialogRef : NgbActiveModal) {

  }

  ngOnInit() {
    
  }

  confirm(){
    this.dialogRef.close(true)
  }

}
