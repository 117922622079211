import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthService implements CanActivate {

  constructor(private router : Router, private cookies : CookieService) { }

  canActivate(){
    return this.isLogin()
      .pipe(
        tap(auth=>{
          if(!auth){
            this.router.navigate(['/']);
          }
        })
      )
  }
  isLogin() : Observable<boolean>{
    return new Observable(observer=>{
      if(this.cookies.check('token_admin_kezora') )
        if(this.cookies.get('token_admin_kezora') )
          observer.next(false);
        else
          observer.next(true);
      else observer.next(true);
    });
  }
}
