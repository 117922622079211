import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[uppercase]'
})
export class UpperCaseDirective {
  @Input() uppercase: string;

  @HostListener('input', ['$event']) onInput(event: Event) {
    const target = event.target as HTMLInputElement;

    // Get current caret position
    const start = target.selectionStart;
    const end = target.selectionEnd;

    // Convert input value to uppercase
    const upperCaseValue = target.value.toUpperCase();
    target.value = upperCaseValue;

    // Emit a new input event to update the Angular binding
    target.dispatchEvent(new Event('input', { bubbles: true }));

    // Restore caret position
    if (start !== null && end !== null) {
      target.setSelectionRange(start, end);
    }
  }
}
