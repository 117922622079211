
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-data-transport',
  templateUrl: './data-transport.component.html',
  styleUrls: ['./data-transport.component.scss']
})
export class DataTransportComponent implements OnInit {
  data_transport : any = []
  isLoading = false
  nama = ''
  constructor(private go : GoService, private router : Router, private toast : ToastrService) {
    this.getData()
  }

  tambah(){
    this.router.navigateByUrl('/settings/angkutan/tambah')
  }

  getData(){
    this.isLoading = true
    this.go.authHttp.get('transports/list')
    .subscribe((res : any)=>{
      this.data_transport = res
      this.isLoading = false
    })
  }

  hapus(item){
    if(item?.trucks.length>0){
      this.toast.warning('Angkutan ' + item.name + ' tidak dapat dihapus karena masih memiliki data truk', 'Angkutan')
      return false
    }

    this.go.showConfirm('Hapus Angkutan', 'Apakah anda yakin akan menghapus data ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('transports/' + item.id) 
        .subscribe((res : any)=>{
          this.toast.success('Data angkutan berhasil dihapus' , 'Angkutan')
          this.getData()
        }, error=>{
          this.toast.error('Data angkutan gagal dihapus' , 'Angkutan')
        })
      }
    })
  }

  ngOnInit(): void {
  }

}
