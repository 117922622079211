import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PRIVILEGES, URL_ORIGIN } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-data-pegawai',
  templateUrl: './data-pegawai.component.html',
  styleUrls: ['./data-pegawai.component.scss']
})
export class DataPegawaiComponent implements OnInit {
  data : any = []
  page = 0
  total = 0
  length = 0
  total_page = 0
  isLoading = false
  current_page = 1
  urls = URL_ORIGIN
  privileges = PRIVILEGES
  search = ''
  constructor(private go : GoService, private toast : ToastrService, private router : Router, private activateRoutes : ActivatedRoute) { 
    this.activateRoutes.queryParams.subscribe(param=>{
      if(param.page || param.page == 0){
        this.page = param.page
        this.current_page = Number(this.page) + 1
      }

      if(param.search)
        this.search = param.search

      this.getData() 
    })
  }

  doCari(){
    this.page = 0
    this.router.navigateByUrl('/user/data?page=' + this.page + decodeURIComponent('&search='+ this.search))
  }

  getData(){
    this.isLoading = true
    this.go.authHttp.get('users/data?page=' + this.page + '&keyword=' + this.search)
    .subscribe((res : any)=>{
      this.data = res.data
      this.total = res.total
      this.isLoading = false

      this.length = this.data.length
      this.total_page = this.total
    }, eror=>{
      this.isLoading  = false
    })
  }

  changePage(ev){
    console.log(ev)
    this.page = ev - 1
    this.router.navigateByUrl('/user/data?page=' + this.page)
  }

  hapus(item){
    var id = item.id
    this.go.showConfirm('Data User', 'Apakah anda yakin akan menghapus data ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('users/' + id)
        .subscribe((res: any)=>{
          this.toast.success('Data user berhasil dihapus', 'Data User')

          this.getData()
        }, error=>{
          this.toast.error('Data user gagal dihapus', 'Data User')
        })
      }
    })
  }

  ngOnInit(): void {
  }

}
