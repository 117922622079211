<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Data Invoice" [options]="false">
            <div class="tabel-container">
                <ngb-progressbar *ngIf="isLoading" [value]="100" [striped]="true" [animated]="true"></ngb-progressbar>
                <table class="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Kode Order</th>
                            <th>Tanggal</th>
                            <th>Tgl Invoice</th>
                            <th>Customer</th>
                            <th>Nama Pasien</th>
                            <th>Cabang</th>
                            <th class="text-right">Total</th>
                            <th> </th>
                            <th> Diupdate </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of data; let i = index">
                            <td>{{(i + 1) + (page * 20)}}</td>
                            <td>{{item.kode_order}}</td>
                            <td>{{item.tanggal | date:"dd/MM/yyyy"}}</td>
                            <td>{{item.tanggal_invoice | date:"dd/MM/yyyy"}}</td>
                            <td>{{item.customer}}</td>
                            <td>{{item.nama_pasien}}</td>
                            <td>{{item.cabang}}</td>
                            <td class="text-right">Rp.{{item.total | number}}</td>
                            <td>
                                <span class="badge badge-sm" [ngClass]="{'badge-info' : item.status_invoice == 1, 'badge-warning' : item.status_invoice == 0, 'badge-success' : item.status_invoice == 2}">
                                    {{status_invoice[item.status_invoice].text}}
                                </span>
                            </td>
                            <td>
                                {{item.date | date:"dd/MM/yy HH:mm"}}
                            </td>
                            <td>
                                <a routerLink="/order/invoices/detail/{{item.id}}">Detail</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="horizontal-scroll"><ngb-pagination [collectionSize]="total_page" [pageSize]="20" [(page)]="current_page" (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination></div>
        </app-card>
    </div>
</div>