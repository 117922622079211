import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { days, roles, transaction_type_id } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-jual-keoutlet',
  templateUrl: './jual-keoutlet.component.html',
  styleUrls: ['./jual-keoutlet.component.scss']
})
export class JualKeoutletComponent implements OnInit {
  data_tap: any = []
  data_product: any = []
  data_stocks: any = []
  data_outlets : any = []
  days = days
  filter = {
    entity_id: null,
    product_id : null,
    from_date : this.go.formatDate(new Date()),
    end_date : this.go.formatDate(new Date()),
    transaction_type_id : transaction_type_id.JUAL_TAP_OUTLET
  }
  roles = roles

  isLoading = false

  items: any = []

  transaction = {
    vendor_id: null,
    customer_id: null,
    entity_id: null,
    product_id: null,
    opening_qty: 0,
    qty: null,
    price : 0,
    description: '',
    transaction_type_id: transaction_type_id.JUAL_TAP_OUTLET,
    stock_id: null
  }

  data_transactions : any = []

  item : any = {}

  
  constructor(private go: GoService, private toast: ToastrService) {
    this.getDataTap()
    this.getDataProduct()
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  getDataOutlet(){
    this.go.authHttp.get('entities?entity_type_id=3&parent_id=' + this.transaction.entity_id)
    .subscribe((res : any)=>{
      this.data_outlets = res
    })
  }

  getDataProduct() {
    this.go.authHttp.get('products')
      .subscribe((res: any) => {
        this.data_product = res.data
      })
  }

  selectTAP(ev) {
    if (ev) {
      this.filter.entity_id = ev.id
    }
  }

  isLoadingstock = false
  selectTAP2(ev) {
    if (ev) {
      this.transaction.vendor_id = ev.id
      this.transaction.entity_id = ev.id
      this.transaction.opening_qty = 0
      this.item.vendor = ev.name
      this.getDataOutlet()
      this.getStocks()
    }
  }

  getStocks() {
    this.data_stocks = []

    this.isLoadingstock = true
    this.go.authHttp.get('transactions/stocks?entity_id=' + this.transaction.entity_id)
      .subscribe((res: any) => {
        this.data_stocks = res
        this.isLoadingstock = false
        this.getOpeningQty()
      })
  }

  is_topup = 0
  selectProduct(ev) {
    if (ev) {
      this.transaction.product_id = ev.id
      this.transaction.price = ev.price
      this.item.product = ev.name
      this.is_topup = ev.is_topup
      this.getOpeningQty()
    }
  }

  getOpeningQty() {
    this.transaction.opening_qty = 0
    var selected = this.data_stocks.filter(it => it.product_id == this.transaction.product_id)
    if (selected.length > 0) {
      this.transaction.stock_id = selected[0].id
      this.transaction.opening_qty = selected[0].qty
    }else
      this.transaction.stock_id = null
  }

  doSimpan() {
    if (this.isLoading) return false
    if(this.transaction.qty > this.transaction.opening_qty && !this.is_topup){
      this.toast.warning('Qty > sisa stock di TAP', '')
      return false
    }
    this.isLoading = true

    this.go.authHttp.post('transactions/create', this.transaction)
      .subscribe((res: any) => {
        this.isLoading = false

        let selected = this.data_outlets.filter(it=> it.id == this.transaction.customer_id)
        this.data_transactions.push({
          product : this.item.product,
          vendor : this.item.vendor,
          customer : selected[0].name,
          price : this.transaction.price,
          qty : this.transaction.qty,
          subtotal : this.transaction.price * this.transaction.qty
        })

        this.transaction.product_id = null
        this.transaction.qty = null
        this.transaction.opening_qty = 0
        this.transaction.description = ''
        this.transaction.price = 0
        this.toast.success('Data transaksi berhasil disimpan', 'Jual Ke Outlet')
        this.getStocks()
      }, error => {
        console.log(error)
        this.isLoading = false
        this.toast.error(error.error.message, 'Jual Ke Outlet')
      })
  }

  doReset(){
    this.transaction.product_id = null
    this.transaction.qty = null
    this.transaction.opening_qty = 0
    this.transaction.description = ''
    this.transaction.price = 0
    this.transaction.entity_id = null
    this.transaction.vendor_id = null
    this.data_transactions = []
  }

  getTotal(){
    let total = 0
    for(let item of this.data_transactions) total += item.subtotal
    return total
  }

  doCari() {
    this.isLoading = true
    let params = ''

    let params_ar = []
    for(let key in this.filter){
      if(this.filter[key]) params_ar.push(key + '=' + this.filter[key])
    }
    params = params_ar.join('&')
    this.items = []
    this.go.authHttp.get('transactions/list?' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = res
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Jual Ke Outlet')
      })
  }

  reset() {
    this.items = []
    this.filter.entity_id = null
    this.filter.product_id = null
    this.filter.from_date = null
    this.filter.end_date = null
  }

  isDisabled() {
    return false
  }

  ngOnInit(): void {
  }

}
