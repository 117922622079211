import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { OPTION_CURRENCY_MASK, PALKA } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-muatan-keluar',
  templateUrl: './muatan-keluar.component.html',
  styleUrls: ['./muatan-keluar.component.scss']
})
export class MuatanKeluarComponent implements OnInit {
  option_currency = OPTION_CURRENCY_MASK
  isLoading = false

  transaction = {
    warehouse_id: null,
    contract_id: null,
    order_id: null,
    actual_date: this.go.formatDate(new Date()),
    party: null,
    party_nett: null,
    stock_qty: null,
    description: ''
  }

  transaction2 = {
    entity_id: null
  }

  data_gudang: any = []
  data_contracts: any = []
  baggage_receipts: any = []
  product: any = {}
  data_orders: any = []
  palka = PALKA

  data_baggages: any = []

  showAll = false

  constructor(private go: GoService, private toast: ToastrService) {
    this.getGudang()
  }

  getGudang() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_gudang = res
      })
  }


  isLoadingstock = false
  selectGudang(ev) {
    if (ev) {
      this.getContract(ev.id)
    }
  }

  selectKapal(ev) {
    this.product = {}
    this.transaction.party = null
    this.transaction.party_nett = null
    this.transaction.stock_qty = null
    this.data_orders = []
    if (ev) {
      const selected = this.data_contracts.filter(it => it.id === ev.id)
      if (selected.length > 0) {
        this.product = selected[0]?.product
        this.transaction.party = selected[0].party
        this.transaction.party_nett = selected[0].party_nett
        this.transaction.stock_qty = selected[0].stock_qty
        this.getBaggageMasuk()
        this.getDataOrder(ev.id)
      }
    }
  }

  getDataOrder(id) {
    this.go.authHttp.get('orders/list-no-page?only_with_stock=1&contract_id=' + id)
      .subscribe((res: any) => {
        this.data_orders = res
      })
  }

  getContract(warehouse_id) {
    this.isLoadingstock = true
    this.go.authHttp.get('bagging-contract/list?warehouse_id=' + warehouse_id)
      .subscribe((res: any) => {
        this.data_contracts = res
        this.isLoadingstock = false
      })
  }


  jumlahTrip = 0
  totalBaggage = 0
  getBaggageMasuk() {
    this.data_baggages = []
    this.jumlahTrip = 0
    this.totalBaggage = 0
    const { contract_id, actual_date } = this.transaction
    if (contract_id && actual_date) {
      this.go.authHttp.get('shippings/list-no-page?contract_id=' + contract_id + '&actual_date=' + actual_date)
        .subscribe((res: any) => {
          this.data_baggages = res
          for (let item of this.data_baggages) {
            this.totalBaggage += item.qty
          }
          this.jumlahTrip = this.data_baggages.length
        }, error => {
          this.data_baggages = []
        })
    }
  }


  doSimpan() {
    if (this.isLoading) return false
    this.isLoading = true
    let total = 0
    for (let item of this.baggage_receipts) {
      item.actual_date = this.transaction.actual_date
      item.warehouse_id = this.transaction.warehouse_id
      item.contract_id = this.transaction.contract_id
      item.description = this.transaction.description

      if (item.qty > item.stock_qty) {
        this.toast.warning('Total muat melebihi stock yang ada di DO ' + item.code, '')
        this.isLoading = false
        return false
      }

      total += item.qty
    }

    if (total > this.transaction.stock_qty) {
      this.toast.warning('Total muat melebihi stock yang tersedia di Gudang', '')
      this.isLoading = false
      return false
    }

    this.go.authHttp.post('shippings', this.baggage_receipts)
      .subscribe((res: any) => {
        this.isLoading = false
        this.toast.success('Data barang masuk berhasil disimpan', 'Bagging Masuk')
        this.transaction.stock_qty -= total

        this.getBaggageMasuk()
        this.getDataOrder(this.transaction.contract_id)
        this.baggage_receipts = []
      }, error => {
        this.isLoading = false
        this.toast.error(error.error.message, 'Bagging Masuk')
      })
  }

  getUnit() {
    if (this.product?.unit) return ` (${this.product.unit}) `
    else return ''
  }

  reset() {
    this.transaction.contract_id = null
    this.transaction.warehouse_id = null
    this.transaction.description = ''
    this.transaction.party = ''
    this.transaction.party_nett = ''
    this.product = {}
    this.baggage_receipts = []
  }

  tambahAngkutan() {
    this.baggage_receipts.push({
      order_id: null,
      party_do: null,
      stock_qty: null,
      plate_number: null,
      driver_name: null,
      qty: null
    })
  }

  hapusTruk(i) {
    this.baggage_receipts.splice(i, 1)
  }

  selectDO(ev, item, index = 0) {
    item.party_do = null
    item.stock_qty = null

    if (ev) {
      let shippings = JSON.parse(JSON.stringify(this.baggage_receipts))
      shippings.splice(index, 1)
      shippings = shippings.map((itm) => itm.order_id)

      if (shippings.includes(ev.id)) {
        this.toast.warning('Nomor DO ini sudah dipilih sebelumnya, silahkan pilih DO yang lain', '')
        item.order_id = null
        return false
      }
      const selected = this.data_orders.filter(it => it.id === ev.id)
      if (selected.length > 0) {
        item.party_do = selected[0].qty
        item.stock_qty = selected[0].stock_qty
        item.code = selected[0].code
      }
    }
  }

  isDisabled() {
    if (!this.transaction.warehouse_id) return true
    if (!this.transaction.actual_date) return true
    if (this.baggage_receipts.length <= 0) return true
    if (this.baggage_receipts.filter(it => !it.order_id || !it.plate_number || !it.qty || !it.driver_name).length > 0) return true

    return false
  }

  ngOnInit(): void {
  }

}
