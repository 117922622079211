import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { STATUS_INVOICE, STATUS_ORDER } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';
import { ToastService } from 'src/app/theme/shared/components/toast/toast.service';

@Component({
  selector: 'app-data-invoice',
  templateUrl: './data-invoice.component.html',
  styleUrls: ['./data-invoice.component.scss']
})
export class DataInvoiceComponent implements OnInit {
  data : any = []
  page = 0
  total = 0
  length = 0
  total_page = 0
  isLoading = false
  current_page = 1
  status_order = STATUS_ORDER
  status_invoice = STATUS_INVOICE
  user : any = {}
  constructor(private go : GoService, private toast : ToastService, private router : Router, private activateRoutes : ActivatedRoute) { 
    this.activateRoutes.params.subscribe(param=>{
      if(param.page){
        this.page = param.page
        this.current_page = Number(this.page) + 1
      }

      this.getData() 
    })
  }

  getData(){
    this.isLoading = true
    this.go.authHttp.post('order/data-invoice', {page : this.page})
    .subscribe((res : any)=>{
      this.data = res.data
      this.total = res.total
      this.isLoading = false

      this.length = this.data.length
      this.total_page = this.total
    }, eror=>{
      this.isLoading  = false
    })
  }

  changePage(ev){
    this.page = ev - 1
    this.router.navigateByUrl('/order/invoices/page/' + this.page)
  }

  ngOnInit(): void {
  }

}
