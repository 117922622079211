import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
  pure : false
})
export class SearchPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(items: any[], conditions : {[field : string] : any}, abs : boolean): any[] {  
    if(!items) return [];   
      if(conditions == null) return items;   
        if(!abs || abs == null){
        	return items.filter(item => {
	            for (let field in conditions) {
					if((typeof conditions[field] == 'string')){
						var regex = new RegExp(conditions[field], 'i');
						if (item[field].search(regex)<0) {
							return false;
						}
					}else{
						for(let key in conditions[field]){
							var regex = new RegExp(conditions[field][key], 'i');
							if (item[field][key].search(regex)<0) {
								return false;
							}
						}
					}
	            }
	            return true;
	        });
        }else{
        	return items.filter(item => {
	            for (let field in conditions) {
								if((typeof conditions[field]) == 'string'){
									if(conditions[field] == null || conditions[field] == '') break;
									if (item[field].toString() !== conditions[field].toString()) {
												return false;
									}
								}else{
									for(let key in conditions[field]){
										if(conditions[field][key] == null || conditions[field][key] == '') break;
										if (item[field][key].toString() !== conditions[field][key].toString()) {
													return false;
										}
									}
								}
	            }
	            return true;
	        });
        }
        
  }
}
