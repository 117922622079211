<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Laporan Survey Outlet" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Survey Outlet">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.tap_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Survey</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.survey" placeholder="Survey"
                                                [items]="surveys" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.end_date" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button [disabled]="!filter.tap_id || !filter.survey" (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button [disabled]="!filter.tap_id || !filter.survey" (click)="doDownload()" class="btn btn-info">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Download Excel
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table" style="font-size: 12px;">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>TAP</th>
                                            <th>Sales Force</th>
                                            <th>ID Digipos</th>
                                            <th>Outlet</th>
                                            <th>Kecamatan</th>
                                            <th>Product</th>
                                            <th>Deskripsi</th>
                                            <th>Tanggal</th>
                                            <th class="text-right">Durasi</th>
                                            <th class="text-right">Quota</th>
                                            <th class="text-right">TP</th>
                                            <th class="text-right">EUP</th>
                                            <th class="text-right">Weekly</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{((filter.page - 1) * 20) + i+1}}</td>
                                            <td>{{item.tap ? item.tap.name : '-'}}</td>
                                            <td>{{item.sales ? item.sales.name : '-'}}</td>
                                            <td>{{item.outlet ? item.outlet.id_digipos : '-'}}</td>
                                            <td>{{item.outlet ? item.outlet.name : '-'}}</td>
                                            <td>{{item.outlet ? item.outlet.regency.name : ''}}</td>
                                            <td>{{item.product ? item.product.name : '-'}}</td>
                                            <td>{{item.description}}</td>
                                            <td>{{item.createdAt | date:"dd/MM/yyyy HH:mm"}}</td>
                                            <td class="text-right">{{item.expired_name}}</td>
                                            <td class="text-right">{{item.quota}}</td>
                                            <td class="text-right">{{item.tp | number}}</td>
                                            <td class="text-right">{{item.eup | number}}</td>
                                            <td class="text-right">{{item.weekly | number}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination [collectionSize]="total_page" [pageSize]="20" [(page)]="filter.page" (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination></div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>