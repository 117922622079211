import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { days, roles } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-peta-kunjugan',
  templateUrl: './peta-kunjungan.component.html',
  styleUrls: ['./peta-kunjungan.component.scss']
})
export class PetaKunjunganComponent implements OnInit {
  data_tap: any = []
  data_sf: any = []
  filter = {
    entity_id: null,
    user_id: null,
    from_date: this.go.formatDate(new Date()),
    end_date: this.go.formatDate(new Date()),
    page: 1
  }
  roles = roles

  isLoading = false

  items: any = []
  total_page = 0

  mode_map = ''
  initLng = 98.68073699817948
  initLat = 3.579070780816168

  constructor(private go: GoService, private toast: ToastrService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.getDataTap()
    this.activatedRoute.queryParams.subscribe(param => {
      for (let key in param)
        if (key == 'entity_id' || key == 'user_id' || key == 'page')
          this.filter[key] = Number(param[key])
        else this.filter[key] = param[key]
      console.log(this.filter)
      if (this.filter.entity_id) this.doCari()
    })
  }

  getDataTap() {
    this.go.authHttp.get('entities?entity_type_id=2')
      .subscribe((res: any) => {
        this.data_tap = res
      })
  }

  selectTAP(ev) {
    if (ev) {
      this.filter.entity_id = ev.id
      this.getDataUsers()
    }
  }

  changePage(ev) {
    this.filter.page = Number(ev)
    this.doCari()
  }

  getDataUsers() {
    this.go.authHttp.get('users/list?role=' + this.roles.SALES_FORCE + '&entity_id=' + this.filter.entity_id)
      .subscribe((res: any) => {
        this.data_sf = res
      })
  }

  doRefresh() {
    this.filter.page = 1
    this.doCari()
  }

  doCari() {
    if (this.isLoading) return false
    this.isLoading = true
    this.filter.end_date = this.filter.from_date
    let params = '?from_date=' + this.filter.from_date + '&end_date=' + this.filter.end_date
    params += this.filter.entity_id ? '&entity_id=' + this.filter.entity_id : ''
    params += this.filter.user_id ? '&user_id=' + this.filter.user_id : ''
    params += '&limit=1000'

    this.router.navigateByUrl('/sales-force/peta-kunjungan' + params)

    this.go.authHttp.get('sales-force/visits/v2' + params)
      .subscribe((res: any) => {
        this.isLoading = false
        this.items = []
        for (let item of res.data) {
          this.initLng = item.longitude
          this.initLat = item.latitude
          this.items.push({
            longitude: item.longitude,
            latitude: item.latitude,
            icon : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            content: `<br><strong><a target='_blank' href='/#/sales-force/kunjungan/detail/${item.id}'>${item.outlet.name}</a></strong><br><small>${item.sales?.name}</small><br>${ moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}`
          })
        }
        
        console.log(this.items)
      }, error => {
        this.isLoading = false
        this.toast.error('Data gagal ditampilkan', 'Kunjungan SF')
      })
  }

  isDisabled() {
    for (let key in this.filter) {
      if (!this.filter[key]) return true
    }
    return false
  }

  ngOnInit(): void {
  }

}
