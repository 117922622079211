<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Kunjungan Outlet Sales Force" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                        <div class="col-sm-9">
                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sales Force</label>
                        <div class="col-sm-9">
                            <ng-select [(ngModel)]="filter.user_id" placeholder="Pilih Sales Force" [items]="data_sf"
                                bindLabel="name" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Kunjungan</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                placeholder="Tanggal Mulai">
                        </div>
                    </div>
                </div>


            </div>
            <div class="row">
                <div class="col-md-12">
                    <button [disabled]="!(filter.entity_id && filter.from_date && filter.end_date)"
                        (click)="doRefresh()" class="btn btn-success">
                        <span *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm" role="status"></span>
                            Loading...
                        </span>
                        <span *ngIf="!isLoading">
                            Cari
                        </span>
                    </button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12">
                    <div class="map-container" style="min-height:300px">
                        <app-map-container *ngIf="!isLoading" [mode]="mode_map" [markers]="items" [longitude]="initLng"
                            [latitude]="initLat" [zoom]="14"></app-map-container>
                    </div>
                </div>
            </div>
        </app-card>
    </div>
</div>