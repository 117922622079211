<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Sales Plan Outlet" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Sales Plan">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tipe Produk</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.product_type_id" placeholder="Pilih Tipe Produk"
                                                [items]="data_product" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Periode</label>
                                        <div class="col-sm-5">
                                            <ng-select [(ngModel)]="filter.month" placeholder="Pilih Bulan"
                                                [items]="months" bindLabel="value" bindValue="id"></ng-select>
                                        </div>
                                        <div class="col-sm-4">
                                            <ng-select [(ngModel)]="filter.year" placeholder="Pilih Tahun"
                                                [items]="years" bindLabel="value" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Outlet</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.keyword" type="search" class="form-control"
                                                placeholder="Cari nama outlet">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" [disabled]="isDisabled()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button [disabled]="isDisabled()" (click)="doDownload()" class="btn btn-info">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Download Excel
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table" style="font-size: 11px;">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">No.</th>
                                            <th rowspan="2">ID Digipos</th>
                                            <th rowspan="2">Outlet</th>
                                            <th rowspan="2">TAP</th>
                                            <th rowspan="2">Sales Force</th>
                                            <th rowspan="2">Kecamatan</th>
                                            
                                            <th colspan="6" class="text-center sales-plan">Sales Plan</th>
                                            <th colspan="6" class="text-center realisasi">Realisasi</th>
                                            <th colspan="6" class="text-center percent">Progress</th>
                                        </tr>
                                        <tr>
                                            <th *ngFor="let exp of expired_data" class="text-center sales-plan">
                                                {{exp.value}}
                                            </th>
                                            <th class="text-center sales-plan">Total</th>
                                            <th *ngFor="let exp of expired_data" class="text-center realisasi">
                                                {{exp.value}}
                                            </th>
                                            <th class="text-center realisasi">Total</th>
                                            <th *ngFor="let exp of expired_data" class="text-center percent">
                                                {{exp.value}}
                                            </th>
                                            <th class="text-center percent">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{(filter.page * 20) + i+1}}</td>
                                            <td>{{item.id_digipos}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.parent.name}}</td>
                                            <td>{{item.sales_force.length>0 ? item.sales_force[0].name : '-'}}</td>
                                            <td>{{item.regency.name}}</td>

                                            <td *ngFor="let exp of expired_data" class="text-center">
                                                {{getValues(item.sales_plan, exp.id) | number:'0.0-0'}}
                                            </td>
                                            <td class="text-center text-bold">{{getValues(item.sales_plan, 'total') | number:'0.0-0'}}</td>
                                            <td *ngFor="let exp of expired_data" class="text-center">
                                                {{getValues(item.transactions, exp.id) | number:'0.0-0'}}
                                            </td>
                                            <td class="text-center text-bold">{{getValues(item.transactions, 'total') | number:'0.0-0'}}</td>
                                            <td *ngFor="let exp of expired_data" class="text-center">
                                                {{getProgress(item.sales_plan, item.transactions, exp.id)}}
                                            </td>
                                            <td class="text-center text-bold"> {{getProgress(item.sales_plan, item.transactions, 'total')}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="!isLoading" class="horizontal-scroll"><ngb-pagination [collectionSize]="total_page" [pageSize]="20" [(page)]="current_page" (pageChange)="changePage($event)" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"></ngb-pagination></div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Upload Sales Plan">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="upload.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tipe Produk</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="upload.product_type_id" placeholder="Pilih Tipe Produk"
                                                [items]="data_product" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Periode</label>
                                        <div class="col-sm-5">
                                            <ng-select [(ngModel)]="upload.month" placeholder="Pilih Bulan"
                                                [items]="months" bindLabel="value" bindValue="id"></ng-select>
                                        </div>
                                        <div class="col-sm-4">
                                            <ng-select [(ngModel)]="upload.year" placeholder="Pilih Tahun"
                                                [items]="years" bindLabel="value" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">File Excel</label>
                                        <div class="col-sm-9">
                                            <input type="file" (change)="getFiles($event)" [(ngModel)]="upload.file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  class="form-control"
                                                placeholder="Pilih file">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doUpload()" [disabled]="isDisabled2()" class="btn btn-success">
                                        <span *ngIf="isLoading2">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading2">
                                            Upload
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="downloadFormat()" class="btn btn-info">
                                        <span>
                                            Download Format File
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>