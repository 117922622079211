
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-data-tipe-product',
  templateUrl: './data-tipe-product.component.html',
  styleUrls: ['./data-tipe-product.component.scss']
})
export class DataTipeProductComponent implements OnInit {
  data_product : any = []
  isLoading = false
  nama = ''
  constructor(private go : GoService, private router : Router, private toast : ToastrService) {
    this.getData()
  }

  tambah(){
    this.router.navigateByUrl('/settings/tipe-product/tambah')
  }

  getData(){
    this.isLoading = true
    this.go.authHttp.get('product-types')
    .subscribe((res : any)=>{
      this.data_product = res.data
      this.isLoading = false
    })
  }

  hapus(item){
    this.go.showConfirm('Hapus Tipe Product', 'Apakah anda yakin akan menghapus data ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('product-types/' + item.id) 
        .subscribe((res : any)=>{
          this.toast.success('Data tipe product berhasil dihapus' , 'Product')
          this.getData()
        }, error=>{
          this.toast.error('Data tipe product gagal dihapus' , 'Product')
        })
      }
    })
  }

  ngOnInit(): void {
  }

}
