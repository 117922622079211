import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintService } from 'src/app/services/print.service';
import { GoService } from '../../services/go.service';


@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent implements OnInit {
  @Input() public data;
  order : any = {}
  detail_order : any = []
  constructor(private modalRef :NgbActiveModal, private go : GoService, private printService : PrintService) { 
    
    //this.order = this.data

    console.log(this.data)
  }

  ngOnInit() {
    console.log(this.data)
    this.go.authHttp.get('order/data-order-invoice/' + this.data.id)
    .subscribe((res : any)=>{
      this.order = res.order
      for(let item of res.details){
        var selected = this.detail_order.filter(it => it.warna == item.warna && it.id_product == item.id_product)
        if(selected.length>0){
          var index = this.detail_order.indexOf(selected[0])
          this.detail_order[index].posisi.push({pos : item.posisi})
        }else{
          var data = {
            id_product : item.id_product,
            nama_product : item.nama_product,
            bahan_product : item.bahan_product,
            warna : item.warna,
            harga : item.harga_product,
            posisi : [{pos : item.posisi}]
          }
          this.detail_order.push(data);
          
        }
      }
      //this.detail_order = res.details
    })
  }

  getSubTotal() {
    let total = 0;
    for (let itm of this.detail_order) total += itm.harga * itm.posisi.length
    return total
  }

  getTotal() {
    var total = this.getSubTotal() + this.order.ongkir + this.order.extra_charge
    total = total - this.order.poin
    if (this.order.diskon) total = total - (this.order.diskon / 100 * this.getSubTotal())
    return total < 0 ? 0 : total;
  }
  
  tutup(){
    this.modalRef.close();
  }

  printSticker(){
    this.printService.printInvoice('invoice')
  }

}
