import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { entity_types } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-data-cabang',
  templateUrl: './data-cabang.component.html',
  styleUrls: ['./data-cabang.component.scss']
})
export class DataCabangComponent implements OnInit {
  isLoading = false
  data : any = []
  entity_types = entity_types
  constructor(private go : GoService, private router : Router, private toastr : ToastrService) { 
    this.getData()
  }

  getData(){
    this.isLoading = true
    this.go.authHttp.get('entities?entity_type_id=1,2')
    .subscribe((res : any)=>{
      this.data = res
      this.isLoading = false
    }, error=>{
      this.isLoading = false
      this.toastr.warning('Data cabang gagal didapatkan dari server', 'Data Cabang')
    })
  }

  hapus(index){
    var item = this.data[index]
    this.go.showConfirm('Hapus Cabang', 'Apakah anda yakin akan menghapus data cabang ini ?')
    .result.then(res=>{
      if(res){
        this.go.authHttp.delete('entities/' + item.id)
        .subscribe((res : any)=>{
          this.toastr.success('Data cabang berhasil dihapus', 'Data Cabang')
          this.data.splice(index , 1)
        })
      }
    })
  }

  cabangBaru(){
    this.router.navigate(['settings', 'cabang', 'baru'])
  }

  ngOnInit(): void {
  }

}
