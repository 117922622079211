<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Laporan Transaksi" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Transaksi">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sales Force</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.sales_id" placeholder="Pilih Sales Force"
                                                [items]="data_sf" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.end_date" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="doDownload()" class="btn btn-info">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Download Excel
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table" style="font-size: 12px;">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>ID Transaksi</th>
                                            <th>TAP</th>
                                            <th>Sales Force</th>
                                            <th>Outlet</th>
                                            <th>Nama Product</th>
                                            <th>Tipe Product</th>
                                            <th>Tanggal</th>
                                            <th class="text-right">Stok Awal</th>
                                            <th class="text-right">Qty</th>
                                            <th class="text-right">Stok Akhir</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{item.id}}</td>
                                            <td>{{item.vendor ? item.vendor.name : '-'}}</td>
                                            <td>{{item.sales ? item.sales.name : '-'}}</td>
                                            <td>{{item.customer ? item.customer.name : '-'}}</td>
                                            <td>{{item.product ? item.product.name : '-'}}</td>
                                            <td>{{item.product.product_type ? item.product.product_type.name : '-'}}
                                            </td>
                                            <td>{{item.createdAt | date:"dd/MM/yyyy HH:mm"}}</td>
                                            <td class="text-right">{{item.opening_qty | number}}</td>
                                            <td class="text-right">{{item.qty | number}}</td>
                                            <td class="text-right">{{item.opening_qty - item.qty | number}}</td>
                                            <td class="text-right">Rp.{{item.price | number}}</td>
                                            <td class="text-right">Rp.{{item.qty * item.price | number}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colspan="11"><strong>Total</strong></td>
                                            <td class="text-right">
                                                <strong>Rp.{{getTotal() | number}}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>