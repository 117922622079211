import { Component, ViewChild, ElementRef, Input, OnInit } from '@angular/core';

declare var google;

@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss']
})
export class MapContainerComponent implements OnInit {
  @ViewChild('map') mapElement: ElementRef;
  @Input('longitude') lng : any;
  @Input('latitude') lat : any;
  @Input('markers') markers : any = []
  @Input('mode') modeMap : any;
  @Input('zoom') zoom : any

  map: any;

  icons = ['assets/images/marker-device.png', 'assets/images/marker-technician.png', 'assets/images/circle-tiny.png']

  constructor() {
    setTimeout(()=> this.loadMap(this.lat, this.lng), 200);
  }

  loadMap(lat, long){
    
    let myLatLng = {lat: parseFloat(lat), lng: parseFloat(long)};
	  let mapOptions = {
	    center: myLatLng,
      zoom: this.zoom ? this.zoom : 15,
      scrollwheel : false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
	  }
   
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    var infowindow = []; 

    for(let i = 0; i<this.markers.length; i++){
      let icon = ''
      if(this.modeMap == 'single'){
        if(i == 0) icon = this.icons[1]
        else icon = this.icons[2]
      }else icon = this.markers[i].icon ? this.markers[i].icon : this.icons[1]
      var item = this.markers[i]
      let LatLng = {lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)};
      let marker = new google.maps.Marker({
        position: LatLng,
        map: this.map,
        icon : icon
      });
      
      infowindow[i] =  new google.maps.InfoWindow({content : item.content});
      if(this.modeMap == 'single'){
        if(i == 0) infowindow[i].open(this.map, marker)
      }else infowindow[i].open(this.map, marker)
        
      

      marker.addListener('click', ()=>{
        infowindow[i].open(this.map, marker)
      })

    }
  }
  

  ngOnInit() {
  }

}
