<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Jual Ke Outlet" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Data Transaksi">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Product</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="filter.product_id" placeholder="Pilih Product"
                                                [items]="data_product" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.from_date" type="date" class="form-control"
                                                placeholder="Tanggal Mulai">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Akhir</label>
                                        <div class="col-sm-9">
                                            <input [(ngModel)]="filter.end_date" type="date" class="form-control"
                                                placeholder="Tanggal Akhir">
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doCari()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Cari
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>ID Transaksi</th>
                                            <th>TAP</th>
                                            <th>Outlet</th>
                                            <th>Nama Product</th>
                                            <th>Tipe Product</th>
                                            <th>Tanggal</th>    
                                            <th class="text-right">Stok Awal</th>
                                            <th class="text-right">Qty</th>
                                            <th class="text-right">Stok Akhir</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Subtotal</th>
                                            <th class="text-center">Dibuat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of items; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{item.id}}</td>
                                            <td>{{item.vendor ? item.vendor.name : '-'}}</td>
                                            <td>{{item.customer ? item.customer.name : '-'}}</td>
                                            <td>{{item.product ? item.product.name : '-'}}</td>
                                            <td>{{item.product.product_type ? item.product.product_type.name : '-'}}</td>
                                            <td>{{item.createdAt | date:"dd/MM/yyyy HH:mm"}}</td>
                                            <td class="text-right">{{item.opening_qty | number}}</td>
                                            <td class="text-right">{{item.qty | number}}</td>
                                            <td class="text-right">
                                                <span *ngIf="!item.product.is_topup">
                                                    {{item.opening_qty - item.qty | number}}
                                                </span>
                                            </td>
                                            <td class="text-right">Rp.{{item.price | number}}</td>
                                            <td class="text-right">Rp.{{item.qty * item.price | number}}</td>
                                            <td class="text-center">{{item.user_created_by ? item.user_created_by.name : '-'}} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Tambah Transaksi">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectTAP2($event)" [(ngModel)]="transaction.entity_id"
                                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Outlet</label>
                                        <div class="col-sm-9">
                                            <ng-select [(ngModel)]="transaction.customer_id"
                                                placeholder="Pilih Outlet" [items]="data_outlets" bindLabel="name"
                                                bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Product</label>
                                        <div class="col-sm-9">
                                            <ng-select [disabled]="isLoadingstock" (change)="selectProduct($event)"
                                                [(ngModel)]="transaction.product_id" placeholder="Pilih Product"
                                                [items]="data_product" bindLabel="name" bindValue="id">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="line">{{item.name}}</div>
                                                    <div class="line"><small>({{item.product_type ?
                                                            item.product_type.name : '-'}})</small></div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Stok Di tangan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [(ngModel)]="transaction.opening_qty"
                                                currencyMask
                                                [options]="{ prefix: '', precision :0, allowNegative : false, align : 'left', nullable: true}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Qty</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="transaction.qty" currencyMask
                                                [options]="{ prefix: '', precision :0, allowNegative : false, align : 'left', nullable: true}" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Harga</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="transaction.price" currencyMask
                                                [options]="{ prefix: '', precision :0, allowNegative : false, align : 'left', nullable: true}" />
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" [(ngModel)]="transaction.description"
                                                type="text" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <button
                                        [disabled]="transaction.qty<=0 || !transaction.entity_id || !transaction.product_id"
                                        (click)="doSimpan()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Simpan
                                        </span>
                                    </button> &nbsp;
                                    <button class="btn btn-danger" (click)="doReset()">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <ngb-progressbar *ngIf="isLoadingstock" [value]="100" [striped]="true"
                                    [animated]="true"></ngb-progressbar>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>TAP</th>
                                            <th>Outlet</th>
                                            <th>Nama Product</th>
                                            <th class="text-right">Harga</th>
                                            <th class="text-right">Qty</th>
                                            <th class="text-right">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data_transactions; let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{item.vendor}}</td>
                                            <td>{{item.customer}}</td>
                                            <td>{{item.product}}</td>
                                            <td class="text-right">Rp.{{item.price | number}}</td>
                                            <td class="text-right">{{item.qty | number}}</td>
                                            <td class="text-right">Rp.{{item.subtotal | number}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td colspan="5"><strong>Total</strong></td>
                                            <td class="text-right"><strong>Rp.{{getTotal() | number}}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>