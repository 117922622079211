<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Muatan Keluar" [options]="false">
            <ngb-tabset>
                <ngb-tab title="Tambah Transaksi">
                    <ng-template ngbTabContent>
                        <div class="container">
                            <br />
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Gudang</label>
                                        <div class="col-sm-9">
                                            <ng-select (change)="selectGudang($event)"
                                                [(ngModel)]="transaction.warehouse_id" placeholder="Pilih Gudang"
                                                [items]="data_gudang" bindLabel="name" bindValue="id"></ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Kapal</label>
                                        <div class="col-sm-9">
                                            <ng-select [disabled]="isLoadingstock" (change)="selectKapal($event)"
                                                [(ngModel)]="transaction.contract_id" placeholder="Pilih Kapal"
                                                [items]="data_contracts" bindLabel="ship_number" bindValue="id">
                                                <ng-template ng-option-tmp let-item="item">
                                                    <div class="line">{{item.ship_number}}</div>
                                                    <div class="line"><small>({{item?.product?.name || '-'}})</small>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jenis Barang</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [value]="product?.name || ''" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Party
                                            {{getUnit()}}</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [(ngModel)]="transaction.party"
                                                currencyMask [options]="option_currency" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Penerimaan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [(ngModel)]="transaction.party_nett"
                                                currencyMask [options]="option_currency" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sisa Stock</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" readonly [(ngModel)]="transaction.stock_qty"
                                                currencyMask [options]="option_currency" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Keterangan</label>
                                        <div class="col-sm-9">
                                            <input class="form-control" placeholder="Keterangan/Catatan"
                                                [(ngModel)]="transaction.description" type="text" />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal</label>
                                        <div class="col-sm-9">
                                            <input (change)="getBaggageMasuk()" [(ngModel)]="transaction.actual_date"
                                                type="date" class="form-control">
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <table class="table">
                                        <tr>
                                            <th colspan="4">Tanggal</th>
                                            <th class="text-center">Jumlah Trip</th>
                                            <th class="text-center">Total Muat</th>
                                            <th class="text-center">Detail</th>
                                        </tr>
                                        <tr>
                                            <td colspan="4">{{transaction.actual_date | date:"dd/MM/yyyy"}}</td>
                                            <td class="text-center">{{jumlahTrip}}</td>
                                            <td class="text-center">{{totalBaggage | number}} {{product?.unit}}</td>
                                            <td class="text-center">
                                                <input type="checkbox" [(ngModel)]="showAll" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Nomor DO*</th>
                                            <th class="column-number">Party DO</th>
                                            <th class="column-number">Sisa Stock</th>
                                            <th class="column-number">Plat Truk*</th>
                                            <th class="column-number">Supir*</th>
                                            <th class="column-number">Jumlah Muat*</th>
                                            <th></th>
                                        </tr>

                                        <tr *ngFor="let item of baggage_receipts; let i = index">
                                            <td>
                                                <ng-select [(ngModel)]="item.order_id" (change)="selectDO($event, item, i)" placeholder="Pilih DO"
                                                    [items]="data_orders" bindLabel="code" bindValue="id"></ng-select>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" readonly [value]="item.party_do | number" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" readonly [value]="item.stock_qty | number" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="item.plate_number" uppercase type="text" class="form-control" />
                                            </td>
                                            <td>
                                                <input [(ngModel)]="item.driver_name" uppercase type="text" class="form-control" />
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" currencyMask [options]="option_currency" [(ngModel)]="item.qty" />
                                            </td>
                                            <td>
                                                <button (click)="hapusTruk(i)" class="btn btn-outline-danger">
                                                    <i class="feather icon-trash"></i>
                                                </button>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="7">
                                                <button (click)="tambahAngkutan()" class="btn btn-outline-dark">
                                                    + Muat Keluar
                                                </button>
                                            </td>
                                        </tr>

                                        <ng-container *ngIf="showAll">
                                            <tr *ngFor="let item of data_baggages">
                                                <td>{{item?.order?.code || ''}}</td>
                                                <td>{{item?.order?.qty | number}}</td>
                                                <td>{{item?.opening_qty | number}}</td>
                                                <td>{{item.plate_number || ''}}</td>
                                                <td>{{item.driver_name || ''}}</td>
                                                <td>{{item.qty | number}}</td>
                                                <td></td>
                                            </tr>
                                        </ng-container>


                                    </table>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <button (click)="doSimpan()" [disabled]="isDisabled()" class="btn btn-success">
                                        <span *ngIf="isLoading">
                                            <span class="spinner-border spinner-border-sm" role="status"></span>
                                            Loading...
                                        </span>
                                        <span *ngIf="!isLoading">
                                            Simpan
                                        </span>
                                    </button> &nbsp;
                                    <button (click)="reset()" class="btn btn-danger m-2" type="button">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>

                        <br />

                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </app-card>
    </div>
</div>