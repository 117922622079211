import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { privileges } from 'src/app/app.variables';
import { GoService } from 'src/app/services/go.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-pegawai',
  templateUrl: './edit-pegawai.component.html',
  styleUrls: ['./edit-pegawai.component.scss']
})
export class EditPegawaiComponent implements OnInit {

  data_kota: any = []
  isLoading = false
  pegawai = {
    id: 0,
    username : '',
    name: '', email: '', phone_number: '',
    role: 1,
    image: null,
    entity_id: null
  }

  privileges = privileges
  required = ['name', 'email', 'phone_number']

  data_cabang: any = []

  user: any = {}

  constructor(private go: GoService, private userService: UserService, private activatedRoute: ActivatedRoute, public location: Location, private toast: ToastrService) {
    this.activatedRoute.params.subscribe(param => {
      if (param.id) {
        this.pegawai.id = param.id
        this.go.authHttp.get('users/data/' + this.pegawai.id)
        .subscribe((res : any)=>{
          this.pegawai.username = res.username
          this.pegawai.name= res.name
          this.pegawai.email = res.email
          this.pegawai.phone_number = res.phone_number
          this.pegawai.entity_id = res.entity_id
          this.pegawai.role = res.role
        })
      }
    })

    this.user = this.userService.getUser()
    if(this.user.privilege == 0){
      this.privileges.push({
        id : 0, text : 'Super Admin'
      })
    }
    this.pegawai.entity_id = this.user.entity_id
    this.getEntities()
  }

  getEntities() {
    this.go.authHttp.get('entities?entity_type_id=1,2')
      .subscribe((res: any) => {
        this.data_cabang = res
      })
  }

  simpan() {
    this.isLoading = true
    var data = new FormData()
    data.append('id', this.pegawai.id + '')
    data.append('username', this.pegawai.username)
    data.append('name', this.pegawai.name)
    data.append('email', this.pegawai.email)
    data.append('phone_number', this.pegawai.phone_number)
    data.append('entity_id', this.pegawai.entity_id + '')
    data.append('role', this.pegawai.role + '')
    data.append('image', this.pegawai.image)
    this.go.uploadFile('users/edit', data)
      .subscribe((res: any) => {
        if (res.body) {
          this.isLoading = false
          this.toast.success('Data user berhasil diupdate', 'Data User')
          this.location.back()
        }
      }, error => {
        this.isLoading = false
        this.toast.error('Data user gagal diupdate', 'Data User')
      })
  }

  getImage(ev) {
    if (ev.target.files) {
      this.pegawai.image = ev.target.files[0]
    }
  }

  doSimpan() {
    this.simpan()
  }

  isDisabled() {
    for (let key of this.required) {
      if (!this.pegawai[key]) return true
    }
    return false
  }

  reset() {
    this.pegawai.id = 0
    this.pegawai.username = ''
    this.pegawai.name = ''
    this.pegawai.email = ''
    this.pegawai.phone_number = ''
    this.pegawai.image = null
  }

  ngOnInit(): void {
  }

}
