import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, Route} from '@angular/router';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
 
@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate{

  constructor(private router : Router, private cookies : CookieService, private activatedRoute : ActivatedRoute) { }

  canActivate(){
    return this.isLogin()
      .pipe(
        tap(auth=>{
          if(!auth){
            this.router.navigate(['auth/signin']);
          }else{
            //console.log(this.activatedRoute.snapshot.data)
          }
        })
      )
  }
  isLogin() : Observable<boolean>{
    return new Observable(observer=>{
      if(this.cookies.check('token_admin_kezora'))
        if(this.cookies.get('token_admin_kezora'))
          observer.next(true);
        else
          observer.next(false);
      else observer.next(false);
    });
  }
}
