<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="{{cabang.id == 0 ? 'Cabang Baru' : 'Edit Cabang'}}" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Kode Kantor/Gudang</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="cabang.code" type="text" class="form-control"
                                placeholder="Kode Kantor/Gudang">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Kantor/Gudang</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="cabang.name" type="text" class="form-control"
                                placeholder="Nama Kantor/Gudang">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Nomor Telp</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="cabang.phone_number" type="tel" class="form-control"
                                placeholder="Nomor Telpon">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Alamat</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="cabang.address" type="text" class="form-control"
                                placeholder="Alamat cabang">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tipe</label>
                        <div class="col-sm-9">
                            <select [(ngModel)]="cabang.type_id" class="form-control"
                                placeholder="Tipe Kantor/Gudang">
                                <option value="1">Kantor</option>
                                <option value="2">Gudang</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="col-sm-3"> </div>
                    <div class="col-sm-9">
                        <button [disabled]="isDisabled()" (click)="doSimpan()" class="btn  btn-primary m-2"
                            type="button">
                            <span *ngIf="isLoading">
                                <span class="spinner-border spinner-border-sm" role="status"></span>
                                Loading...
                            </span>
                            <span *ngIf="!isLoading">
                                Simpan
                            </span>
                        </button>

                        <button (click)="location.back()" class="btn btn-danger m-2" type="button">
                            Kembali
                        </button>
                    </div>
                </div>
            </div>


        </app-card>
    </div>
</div>