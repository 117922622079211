import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GoService } from 'src/app/services/go.service';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss']
})
export class NewOrderComponent implements OnInit {
  data_customer: any = []
  order = {
    kode_order: '',
    id_customer: null,
    nama_pasien: '',
    diskon: 0,
    total: 0,
    extra_charge: 0,
    ongkir: 0,
    tanggal: null,
    keterangan: ''
  }

  isLoading = false
  data_products: any = []

  susunan_gigi = [
    /*kiri atas */
    { cx: 36, cy: 190, r: 12, posisi: 18 },
    { cx: 38, cy: 159, r: 12, posisi: 17 },
    { cx: 42, cy: 120, r: 12, posisi: 16 },
    { cx: 47, cy: 90, r: 12, posisi: 15 },
    { cx: 55, cy: 67, r: 11, posisi: 14 },
    { cx: 63, cy: 45, r: 11, posisi: 13 },
    { cx: 84, cy: 32, r: 11, posisi: 12 },
    { cx: 110, cy: 27, r: 11, posisi: 11 },

    /* kanan atas */
    { cx: 141, cy: 27, r: 11, posisi: 21 },
    { cx: 167, cy: 32, r: 11, posisi: 22 },
    { cx: 188, cy: 45, r: 11, posisi: 23 },
    { cx: 196, cy: 67, r: 11, posisi: 24 },
    { cx: 204, cy: 90, r: 11, posisi: 25 },
    { cx: 209, cy: 120, r: 12, posisi: 26 },
    { cx: 213, cy: 159, r: 12, posisi: 27 },
    { cx: 215, cy: 190, r: 12, posisi: 28 },

    /* kiri bawah */
    { cx: 36, cy: 255, r: 12, posisi: 48 },
    { cx: 37, cy: 286, r: 12, posisi: 47 },
    { cx: 47, cy: 325, r: 11, posisi: 46 },
    { cx: 56, cy: 355, r: 11, posisi: 45 },
    { cx: 65, cy: 380, r: 11, posisi: 44 },
    { cx: 72, cy: 400, r: 9, posisi: 43 },
    { cx: 92, cy: 407, r: 9, posisi: 42 },
    { cx: 113, cy: 409, r: 9, posisi: 41 },

    /* kanan bawah */
    { cx: 137, cy: 409, r: 9, posisi: 31 },
    { cx: 158, cy: 407, r: 9, posisi: 32 },
    { cx: 178, cy: 400, r: 9, posisi: 33 },
    { cx: 185, cy: 380, r: 11, posisi: 34 },
    { cx: 194, cy: 355, r: 11, posisi: 35 },
    { cx: 203, cy: 325, r: 11, posisi: 36 },
    { cx: 211, cy: 286, r: 12, posisi: 37 },
    { cx: 210, cy: 255, r: 12, posisi: 38 },
  ]

  product: any = {
    warna: '',
    id_product: null,
    posisi: '',
    nama_product: '',
    bahan_product: '',
    harga_product: 0,
    harga_mitra : 0
  }

  detail_order : any = []

  constructor(private go: GoService, private toast : ToastrService, public location: Location) {
    this.getCustomers()
    this.generateKode()

    this.go.authHttp.get('order/data-product')
      .subscribe((res: any) => {
        this.data_products = res;
      })
  }

  getSubTotal() {
    let total = 0;
    for (let itm of this.detail_order) total += itm.harga_product
    return total
  }

  hapus(index) {
    this.detail_order.splice(index, 1);
  } 

  getTotal() {
    var total = this.getSubTotal() + this.order.ongkir + this.order.extra_charge
    return total <= 0 ? 0 : total;
  }

  selectProduct(ev) {
    if (ev) {
      this.product.nama_product = ev.nama
      this.product.bahan_product = ev.bahan
      this.product.harga_product = ev.harga
      this.product.harga_mitra = ev.harga2
      this.product.poin = ev.poin
    }
  }

  selectedPosisi(posisi) {
    var selected = this.detail_order.filter(it => it.posisi == posisi)
    return selected.length > 0;
  }

  getSelectedPosisi(posisi) {
    var selected = this.detail_order.filter(it => it.posisi == posisi)
    return selected
  }

  getPosisi(posisi) {
    //alert(posisi);
    if (this.product.id_product && this.product.warna) {
      this.product.posisi = posisi
      var selected = this.detail_order.filter(it => it.bahan_product == this.product.bahan_product)
      if (selected.length <= 0) this.detail_order.push(JSON.parse(JSON.stringify(this.product)))
      else {
        var index = this.detail_order.indexOf(selected[selected.length - 1])
        this.detail_order.splice(index + 1, 0, JSON.parse(JSON.stringify(this.product)))
      }

      //this.detail_order.unshift(JSON.parse(JSON.stringify(this.product)));
    } else {
      if (!this.product.id_product) {
        this.toast.warning('Pilih product sebelum memilih posisi gigi', '')
      } else if (!this.product.warna) {
        this.toast.warning('Isi warna sebelum memilih posisi gigi', '')
      }
    }
  }


  isDisabled() {
    if(!this.order.id_customer) return true
    else if(!this.order.nama_pasien) return true
    else if(this.detail_order.length<=0) return true
    else if(!this.order.tanggal) return true
    return false
  }

  generateKode() {
    this.go.authHttp.get('order/generate-kode')
      .subscribe((res: any) => {
        this.order.kode_order = res.id
      })
  }

  getCustomers() {
    this.data_customer = []
    this.go.authHttp.get('customer/data')
      .subscribe((res: any) => {
        this.data_customer = res
      })
  }

  doSimpan() {
    this.isLoading = true
    var order = this.order
    order.total = this.getTotal()
    var details = this.detail_order

    this.go.authHttp.post('order/order-baru', {order : order, details : details})
    .subscribe((res : any)=>{
      this.isLoading = false
      this.toast.success('Data order berhasil ditambahkan', 'Order Baru')
      this.generateKode()
      this.reset()
    }, error=>{
      this.isLoading = false
      this.toast.error('Data order gagal ditambahkan', 'Order Baru')
    })
  }

  reset(){
    this.order.id_customer = null
    this.order.nama_pasien = ''
    this.order.diskon = 0
    this.order.total = 0
    this.order.extra_charge = 0
    this.order.ongkir = 0
    this.order.tanggal = null
    this.order.keterangan = ''
   
  
    this.detail_order = []

    this.product.warna = ''
    this.product.id_product = null
  }

  ngOnInit(): void {
  }

}
