<div class="row">
    <div class="col-md-12">
        <app-card cardTitle="Tracking Lokasi Sales Force" [options]="false">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">TAP</label>
                        <div class="col-sm-9">
                            <ng-select (change)="selectTAP($event)" [(ngModel)]="filter.entity_id"
                                placeholder="Pilih TAP" [items]="data_tap" bindLabel="name" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Sales Force</label>
                        <div class="col-sm-9">
                            <ng-select [(ngModel)]="filter.user_id" placeholder="Pilih Sales Force" [items]="data_sf"
                                bindLabel="name" bindValue="id"></ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal</label>
                        <div class="col-sm-9">
                            <input [(ngModel)]="filter.date" type="date" class="form-control"
                                placeholder="Tanggal Kunjungan">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button [disabled]="isDisabled()" (click)="doCari()" class="btn btn-success">
                        <span *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm" role="status"></span>
                            Loading...
                        </span>
                        <span *ngIf="!isLoading">
                            Cari
                        </span>
                    </button> &nbsp;
                    <button (click)="reset()" class="btn btn-danger"> Reset </button>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12" *ngIf="items.length>0">
                  <div class="map-container" style="min-height:300px">
                    <app-map-container [mode]="mode_map" [markers]="items" [longitude]="initLng" [latitude]="initLat"></app-map-container>
                  </div>
                </div>
            </div>
        </app-card>
    </div>
</div>